export const DOCUMENT_STATUS = {
    UNDER_REVISION: "Under Revision",
    APPROVED: "Approved",
    ACTIVE: "Active",
    ARCHIVED: "Archived"
};

export const USER_ROLE = {
    APPROVER: "APPROVER",
    EDITOR: "EDITOR",
    USER: "USER"
};
