import {AnyAction, Reducer} from "redux";
import {action} from "typesafe-actions";
import {HomeState, IDocument} from "../types";
import {IUser} from "../types/server";

export const types = {
  GET_ASSIGNED_DOCUMENTS_REQUEST: "HOME/GET_ASSIGNED_DOCUMENTS_REQUEST",
  GET_ASSIGNED_DOCUMENTS_SUCCESS: "HOME/GET_ASSIGNED_DOCUMENTS_SUCCESS",
  GET_ASSIGNED_DOCUMENTS_FAILURE: "HOME/GET_ASSIGNED_DOCUMENTS_FAILURE",
  GET_RECENT_DOCUMENTS_REQUEST: "HOME/GET_RECENT_DOCUMENTS_REQUEST",
  GET_RECENT_DOCUMENTS_SUCCESS: "HOME/GET_RECENT_DOCUMENTS_SUCCESS",
  GET_RECENT_DOCUMENTS_FAILURE: "HOME/GET_RECENT_DOCUMENTS_FAILURE"
};

export const initialState: HomeState = {
  fetchingAssigned: false,
  assignedDocuments: [],
  errorAssigned: "",
  fetchingRecent: false,
  recentDocuments: [],
  errorRecent: ""
};

export const reducer: Reducer<HomeState> = (state: HomeState = initialState, action: AnyAction) => {

  switch (action.type) {
    case types.GET_ASSIGNED_DOCUMENTS_REQUEST:
      return {...state, fetchingAssigned: true, errorAssigned: ""};
    case types.GET_ASSIGNED_DOCUMENTS_SUCCESS:
      return {...state, fetchingAssigned: false, assignedDocuments: action.payload.data};
    case types.GET_ASSIGNED_DOCUMENTS_FAILURE:
      return {...state, fetchingAssigned: false, assignedDocuments: [], errorAssigned: action.payload.data};
    case types.GET_RECENT_DOCUMENTS_REQUEST:
      return {...state, fetchingRecent: true, errorRecent: ""};
    case types.GET_RECENT_DOCUMENTS_SUCCESS:
      return {...state, fetchingRecent: false, recentDocuments: action.payload.data};
    case types.GET_RECENT_DOCUMENTS_FAILURE:
      return {...state, fetchingRecent: false, recentDocuments: [], errorRecent: action.payload.data};
    default:
      return state;
  }
};

export const getAssignedDocuments = {
  request: (user: IUser) => action(types.GET_ASSIGNED_DOCUMENTS_REQUEST, {user}),
  success: (data: IDocument[]) => action(types.GET_ASSIGNED_DOCUMENTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_ASSIGNED_DOCUMENTS_FAILURE, error)
};

export const getRecentDocuments = {
  request: () => action(types.GET_RECENT_DOCUMENTS_REQUEST),
  success: (data: IDocument[]) => action(types.GET_RECENT_DOCUMENTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_RECENT_DOCUMENTS_FAILURE, error)
};

export const actions = {
  getAssignedDocuments,
  getRecentDocuments
};

export default reducer;
export {reducer as homeReducer};
