import {Button, Dropdown, Icon, Menu, Modal, Table, Tooltip} from "antd";
import {ColumnProps} from "antd/lib/table";
import moment from "moment";
import {Component} from "react";
import React from "react";
import {IDocument, IDocumentReview, IDocumentVersion} from "../types/server";

interface HistoryModalProps {
  document: IDocument;
  historyModalVisible: boolean;
  setVisible: (visible: boolean) => any;
}

interface Props extends HistoryModalProps {}

class HistoryModal extends Component<Props, {}> {

  public handleOk = () => {
    this.props.setVisible(false);
  }

  public handleClose = () => this.props.setVisible(false);

  public viewDocument = (item: any) => {

    const versionString: string = (item.documentVersionId) ?
      "_v" + item.version.toString() + "_" + item.id.toString() + "_" + item.documentVersionId.toString()
    : "_v" + item.version.toString() + "_" + item.documentId.toString() + "_" + item.id.toString();  // properties different for document vs revisions

    const baseFilename = item.pdfFilename.substring(0, item.pdfFilename.indexOf(versionString)) + ".pdf";

    let url = window.location.origin + "/api/documents/viewDocumentPdf/";
    url += baseFilename;
    url += "?versionString=" + versionString;
    url += (item.documentVersionId) ? "&documentId=" + item.id : "&documentId=" + item.documentId; // properties different for document vs revisions
    url += "&accessToken=" + localStorage.getItem("accessToken");
    window.open(url);
  }

  public downloadDocument = (item: any) => {
    const dotLoc = item.filename.lastIndexOf(".");
    const extension = item.filename.substring(dotLoc, item.filename.length);
    const versionString: string = (item.documentVersionId) ?
      "_v" + item.version.toString() + "_" + item.id.toString() + "_" + item.documentVersionId.toString()
    : "_v" + item.version.toString() + "_" + item.documentId.toString() + "_" + item.id.toString(); // properties different for document vs revisions
    const baseFilename = item.filename.substring(0, item.filename.indexOf(versionString)) + extension;

    let url = window.location.origin + "/api/documents/downloadDocument/";
    url += baseFilename;
    url += "?versionString=" + versionString;
    url += (item.documentVersionId) ? "&documentId=" + item.id : "&documentId=" + item.documentId; // properties different for document vs revisions
    url += "&accessToken=" + localStorage.getItem("accessToken");
    window.open(url);
  }

  public render() {

    const documentVersionList = this.props.document.documentHistory.map((item) => Object.assign(item, {key: item.id}));

    const columns: Array<ColumnProps<IDocumentVersion>> = [
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        defaultSortOrder: "descend",
        sorter: (a: IDocumentVersion, b: IDocumentVersion) => (a.version > b.version ? 1 : -1),
        render: (text: any, record: IDocumentVersion) => {
          const viewHistoryIcon: boolean = (record.pdfFilename !== null && record.pdfFilename !== "") ? true : false;
          const downloadHistoryIcon: boolean = (record.filename !== null && record.filename !== "") ? true : false;
          return (
            <div style={{padding: "3px", width: "50%", display: "flex", justifyContent: "space-around"}}>
              <span style={{marginRight: "7px"}}>{record.version}</span>
              {viewHistoryIcon ?
              <Icon type="file-pdf" onClick={() => this.viewDocument(record)}/> : <p style={{padding: "7px"}}></p>}
              {downloadHistoryIcon ?
              <Icon type="download" onClick={() => this.downloadDocument(record)}/> : <p style={{padding: "7px"}}></p>}
            </div>
          ); },
        width: 70
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 100
      },
      {
        title: "Revised",
        dataIndex: "revisedDate",
        key: "revisedDate",
        render: (text: any, record: IDocumentVersion) => (
          <Tooltip title={record.revisedUserName ? "Revised by " + record.revisedUserName : ""}>
            <div>{record.revisedDate ? moment(record.revisedDate).format("YYYY-MM-DD") : null}</div>
          </Tooltip>
        ),
        width: 125
      },
      {
        title: "Approved",
        dataIndex: "acceptedDate",
        key: "acceptedDate",
        render: (text: any, record: IDocumentVersion) => (
          <Tooltip title={record.acceptedDate ? "Approved by " + record.acceptedUserName : ""}>
            <div>{record.acceptedDate ? moment(record.acceptedDate).format("YYYY-MM-DD") : null}</div>
          </Tooltip>
        ),
        width: 125
      },
      {
        title: "Effective",
        dataIndex: "effectiveDate",
        key: "effectiveDate",
        render: (d: Date) => d ? moment(d).format("YYYY-MM-DD") : null,
        width: 125
      },
      {
        title: "Archived",
        dataIndex: "archivalDate",
        key: "archivalDate",
        render: (d: Date) => d ? moment(d).format("YYYY-MM-DD") : null,
        width: 125
      },
      {
        title: "Revision Note",
        dataIndex: "revisionComment",
        key: "revisionComment",
        width: 200
      },
      {
        title: "Review History",
        render: (text: any, record: IDocumentVersion) => {
          if (!record.reviewHistory || record.reviewHistory.length === 0) {
            return <span style={{color: "grey"}}>Review History</span>;
          }
          const menuItems: any[] = [];
          record.reviewHistory.forEach((docReview: IDocumentReview) => {
            const menuText: string = moment(docReview.reviewedDate).format("YYYY-MM-DD") +
              (docReview.reviewedUserName ? " by " + docReview.reviewedUserName : "");
            menuItems.push(<Menu.Item key={docReview.id}>{menuText}</Menu.Item>);
          });
          const menu = (
            <Menu>
              {menuItems}
            </Menu>
          );
          return (<Dropdown overlay={menu} placement="bottomCenter">
            <span style={{color: "blue"}}>Review History</span>
          </Dropdown>);
        },
        width: 200
      },
    ];

    return (
      <Modal title={this.props.document.title}
             width={1200}
             visible={this.props.historyModalVisible}
             onCancel={this.handleClose}
             footer={[<Button key="Ok" onClick={this.handleOk}>Ok</Button>]}>
        <Table dataSource={documentVersionList}
               columns={columns}/>
      </Modal>
    );
  }
}

export default HistoryModal;
