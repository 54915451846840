import {Select} from "antd";
import {Component} from "react";
import React from "react";

interface SelectListItem {id: any; description: string; }
interface Props {items: SelectListItem[]; placeholder: string; value?: string; onChange?: (value: string) => any; style?: any; }

class SelectWithList extends Component<Props, {}> {

    public onChange = (value: string) => {
      if (this.props.onChange) {
        this.props.onChange(value);
      } else {
        return null;
      }
    }

    public render() {

      const itemSelectList = this.props.items.map((item) => (
        <Select.Option key={item.id} value={item.description}>
          {item.description}
        </Select.Option>
      ));

      return (
        <Select allowClear={true} placeholder={this.props.placeholder} value={this.props.value} onChange={this.onChange} style={this.props.style}>
          {itemSelectList}
        </Select>
      );
    }
}

export default SelectWithList;
