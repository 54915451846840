import {AnyAction, Reducer} from "redux";
import {action} from "typesafe-actions";
import {AppUtilityState, ICategory, IDepartment} from "../types";

export const types = {
  GET_DEPARTMENTS_REQUEST: "APP/GET_DEPARTMENTS_REQUEST",
  GET_DEPARTMENTS_SUCCESS: "APP/GET_DEPARTMENTS_SUCCESS",
  GET_DEPARTMENTS_FAILURE: "APP/GET_DEPARTMENTS_FAILURE",
  GET_CATEGORIES_REQUEST: "APP/GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS: "APP/GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "APP/GET_CATEGORIES_FAILURE",
  GET_DOCUMENT_TYPE_COUNTS_REQUEST: "APP/GET_DOCUMENT_TYPE_COUNTS_REQUEST",
  GET_DOCUMENT_TYPE_COUNTS_SUCCESS: "APP/GET_DOCUMENT_TYPE_COUNTS_SUCCESS",
  GET_DOCUMENT_TYPE_COUNTS_FAILURE: "APP/GET_DOCUMENT_TYPE_COUNTS_FAILURE",
  GET_DOCUMENT_STATUS_COUNTS_REQUEST: "APP/GET_DOCUMENT_STATUS_COUNTS_REQUEST",
  GET_DOCUMENT_STATUS_COUNTS_SUCCESS: "APP/GET_DOCUMENT_STATUS_COUNTS_SUCCESS",
  GET_DOCUMENT_STATUS_COUNTS_FAILURE: "APP/GET_DOCUMENT_STATUS_COUNTS_FAILURE",
  GET_USER_DEPARTMENT_COUNTS_REQUEST: "APP/GET_USER_DEPARTMENT_COUNTS_REQUEST",
  GET_USER_DEPARTMENT_COUNTS_SUCCESS: "APP/GET_USER_DEPARTMENT_COUNTS_SUCCESS",
  GET_USER_DEPARTMENT_COUNTS_FAILURE: "APP/GET_USER_DEPARTMENT_COUNTS_FAILURE",
  GET_USER_PERMISSION_LEVEL_COUNTS_REQUEST: "APP/GET_USER_PERMISSION_LEVEL_COUNTS_REQUEST",
  GET_USER_PERMISSION_LEVEL_COUNTS_SUCCESS: "APP/GET_USER_PERMISSION_LEVEL_COUNTS_SUCCESS",
  GET_USER_PERMISSION_LEVEL_COUNTS_FAILURE: "APP/GET_USER_PERMISSION_LEVEL_COUNTS_FAILURE"

};

export const initialState: AppUtilityState = {
  fetchingDepartments: false,
  departments: [],
  errorDepartments: "",
  fetchingCategories: false,
  categories: [],
  errorCategories: "",
  documentTypeCounts: [],
  documentStatusCounts: [],
  userDepartmentCounts: [],
  userPermissionLevelCounts: []
};

export const reducer: Reducer<AppUtilityState> = (state: AppUtilityState = initialState, action: AnyAction) => {

  switch (action.type) {
    case types.GET_DEPARTMENTS_REQUEST:
      return {...state, fetchingDepartments: true, errorDepartments: ""};
    case types.GET_DEPARTMENTS_SUCCESS:
      return {...state, fetchingDepartments: false, departments: action.payload.data};
    case types.GET_DEPARTMENTS_FAILURE:
      return {...state, fetchingDepartments: false, departments: [], errorDepartments: action.payload.data};
    case types.GET_CATEGORIES_REQUEST:
      return {...state, fetchingCategories: true, errorCategories: ""};
    case types.GET_CATEGORIES_SUCCESS:
      return {...state, fetchingCategories: false, categories: action.payload.data};
    case types.GET_CATEGORIES_FAILURE:
      return {...state, fetchingCategories: false, categories: [], errorCategories: action.payload.data};
    case types.GET_DOCUMENT_TYPE_COUNTS_REQUEST:
      return {...state, error: ""};
    case types.GET_DOCUMENT_TYPE_COUNTS_SUCCESS:
      return {...state, documentTypeCounts: action.payload.data};
    case types.GET_DOCUMENT_TYPE_COUNTS_FAILURE:
      return {...state, fetching: false, documentTypeCounts: [], error: action.payload.data};
    case types.GET_DOCUMENT_STATUS_COUNTS_REQUEST:
      return {...state, error: ""};
    case types.GET_DOCUMENT_STATUS_COUNTS_SUCCESS:
      return {...state, documentStatusCounts: action.payload.data};
    case types.GET_DOCUMENT_STATUS_COUNTS_FAILURE:
      return {...state, fetching: false, documentStatusCounts: [], error: action.payload.data};
    case types.GET_USER_DEPARTMENT_COUNTS_REQUEST:
      return {...state, error: ""};
    case types.GET_USER_DEPARTMENT_COUNTS_SUCCESS:
      return {...state, userDepartmentCounts: action.payload.data};
    case types.GET_USER_DEPARTMENT_COUNTS_FAILURE:
      return {...state, fetching: false, userDepartmentCounts: [], error: action.payload.data};
    case types.GET_USER_PERMISSION_LEVEL_COUNTS_REQUEST:
      return {...state, error: ""};
    case types.GET_USER_PERMISSION_LEVEL_COUNTS_SUCCESS:
      return {...state, userPermissionLevelCounts: action.payload.data};
    case types.GET_USER_PERMISSION_LEVEL_COUNTS_FAILURE:
      return {...state, fetching: false, userPermissionLevelCounts: [], error: action.payload.data};

    default:
      return state;
  }
};

export const getDepartments = {
  request: () => action(types.GET_DEPARTMENTS_REQUEST),
  success: (data: IDepartment[]) => action(types.GET_DEPARTMENTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_DEPARTMENTS_FAILURE, error)
};

export const getCategories = {
  request: () => action(types.GET_CATEGORIES_REQUEST),
  success: (data: ICategory[]) => action(types.GET_CATEGORIES_SUCCESS, data),
  failure: (error: string) => action(types.GET_CATEGORIES_FAILURE, error)
};

export const getDocumentTypeCounts = {
  request: () => action(types.GET_DOCUMENT_TYPE_COUNTS_REQUEST),
  success: (data: Array<{documentType: string, count: number}>) => action(types.GET_DOCUMENT_TYPE_COUNTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_DOCUMENT_TYPE_COUNTS_FAILURE, error)
};

export const getDocumentStatusCounts = {
  request: () => action(types.GET_DOCUMENT_STATUS_COUNTS_REQUEST),
  success: (data: Array<{status: string, count: number}>) => action(types.GET_DOCUMENT_STATUS_COUNTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_DOCUMENT_STATUS_COUNTS_FAILURE, error)
};

export const getUserDepartmentCounts = {
  request: () => action(types.GET_USER_DEPARTMENT_COUNTS_REQUEST),
  success: (data: Array<{department: string, count: number}>) => action(types.GET_USER_DEPARTMENT_COUNTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_USER_DEPARTMENT_COUNTS_FAILURE, error)
};

export const getUserPermissionLevelCounts = {
  request: () => action(types.GET_USER_PERMISSION_LEVEL_COUNTS_REQUEST),
  success: (data: Array<{permissionLevel: string, count: number}>) => action(types.GET_USER_PERMISSION_LEVEL_COUNTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_USER_PERMISSION_LEVEL_COUNTS_FAILURE, error)
};

export const actions = {
    getDepartments,
    getCategories,
    getDocumentTypeCounts,
    getDocumentStatusCounts,
    getUserDepartmentCounts,
    getUserPermissionLevelCounts
};

export default reducer;
export {reducer as appReducer};
