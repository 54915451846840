import {AnyAction, Reducer} from "redux";
import {action} from "typesafe-actions";
import {AuthState, IUser} from "../types";

const jwtDecode = require("jwt-decode");

export const types = {
  LOGIN_REQUEST: "AUTH/LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE: "AUTH/LOGIN_FAILURE",
  LOGOUT_REQUEST: "AUTH/LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "AUTH/LOGOUT_FAILURE",
  UNAUTHORIZED: "AUTH/UNAUTHORIZED",
  SET_USER: "AUTH/SET_USER"
};

export const initialState: AuthState = {
  error: "",
  fetching: false,
  roles: []
};

export const reducer: Reducer<AuthState> = (state: AuthState = initialState, action: AnyAction) => {

  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {...state, fetching: true, error: ""};
    case types.LOGIN_SUCCESS:
      const decodedToken = jwtDecode(action.payload.headers["access-token"]);
      return {...state, fetching: false, authorizedUser: decodedToken.user};
    case types.LOGIN_FAILURE:
      return {...state, fetching: false, authorizedUser: undefined, error: action.payload.data};
    case types.LOGOUT_REQUEST:
      return {...state, fetching: true, error: "", authorizedUser: undefined};
    case types.LOGOUT_SUCCESS:
      return {...state, fetching: false};
    case types.LOGOUT_FAILURE:
      return {...state, fetching: false, error: action.payload.data};
    case types.UNAUTHORIZED:
      return {...state, fetching: false, authorizedUser: null};
    case types.SET_USER:
      return {...state, authorizedUser: action.payload};
    default:
      return state;
  }
};

export const login = {
  request: (username: string, password: string) => action(types.LOGIN_REQUEST, {username, password}),
  success: (data: IUser) => action(types.LOGIN_SUCCESS, data),
  failure: (error: string) => action(types.LOGIN_FAILURE, error)
};

export const logout = {
  request: (userId: number) => action(types.LOGOUT_REQUEST, {userId}),
  success: () => action(types.LOGOUT_SUCCESS),
  failure: (error: string) => action(types.LOGOUT_FAILURE, error)
};

export const actions = {
  login,
  logout
};

export default reducer;
export {reducer as authReducer};
