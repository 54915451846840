import {AutoComplete, Button, Form, Icon, List, Modal, Select} from "antd";
import Radio from "antd/es/radio";
import {SelectValue} from "antd/es/select";
import {FormComponentProps} from "antd/lib/form";
import _ from "lodash";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as appActions} from "../redux/app";
import {actions as userActions} from "../redux/user";
import {AddUserLocalState, AddUserState, ApplicationState, UsersLocalState} from "../types";
import {IDepartment, IDepartmentBase, IUser, IUserSearchCriteria} from "../types/server";

interface Props extends DispatchProps, FormComponentProps, ConnectedAddUserModalDispatchProps, AddUserState {}

class AddUserModal extends Component<Props, AddUserLocalState> {

  public cwoldUserSearch = _.debounce((query: string) => {
    if (!_.isEmpty(query)) {
      this.props.fetchCwolUsers({searchString: query});
    }
  }, 150);

  constructor(props: any) {
    super(props);
    this.state = {
      selectedDepartments: [],
      selectedUserPermissions: "",
      selectedUser: [],
      validUserSelected: false
    };
  }

  public componentDidMount() {
    if (this.props.editUser) {
      this.setState({selectedDepartments: this.props.editUser.departments});
    }
  }

  public componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<AddUserLocalState>, nextContext: any): void {
    if (_.get(this.props.editUser, "id") !== _.get(nextProps.editUser, "id")) {
      if (nextProps.editUser) {
        this.setState({selectedDepartments: nextProps.editUser.departments, selectedUserPermissions: nextProps.editUser.permission , selectedUser: nextProps.editUser, validUserSelected: true});
       } else {
        this.setState({selectedDepartments: [], selectedUserPermissions: "" , selectedUser: [], validUserSelected: false});
      }
    }
  }

  public handleOk = () => {
    this.props.form.setFieldsValue({
      departments: this.state.selectedDepartments
    });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const user: IUser = this.state.selectedUser;
        user.departments = values.departments;
        user.permission = values.permissions === "NONE" ? null : values.permissions.toString();
        this.props.saveUser(user, this.props.lastSearch);
        this.props.setVisible(false);
        this.handleAfterClose();
      }
    });
  }

  public handleClose = () => {
    this.props.setVisible(false);
    this.handleAfterClose();
  }

  public handleAfterClose = () => {
    this.setState({selectedDepartments: []});
    this.setState({departmentToAdd: null});
    this.setState({selectedUser: {}});
    this.setState({validUserSelected: false});
    this.props.selectUser(undefined);
  }

  public cwoldUserSelect = (value: SelectValue, option: Object) => {
    const valAsNumber = parseInt(value.toString());
    const userToAdd: IUser = this.props.cwoldUsers.find((user) => user.legacyUserId === valAsNumber);
    this.setState({selectedUser: userToAdd});
    this.setState({validUserSelected: true});
  }

  public checkUserExists = (rule: any, value: SelectValue, callback: any) => {
    if (value) {
      const valAsNumber = parseInt(value.toString());
      if (!isNaN(valAsNumber)) {
           this.setState({validUserSelected: true});
           callback();
           return;
      }
      this.setState({validUserSelected: false});
      callback();
    }
  }

  public removeDepartment = (selectedDepartmentId: number | undefined) => {
    if (selectedDepartmentId) {
        this.setState({selectedDepartments: _.filter(this.state.selectedDepartments, (d: IDepartmentBase) => d.id !== selectedDepartmentId)});
    }
  }

  public onDepartmentChange = (selectedDepartmentId: number) => {
    const departmentToAdd: IDepartment = this.props.departments.find((department) => department.id === selectedDepartmentId);
    this.setState({departmentToAdd});
  }

  public addDepartment = () => {
    if (this.state.departmentToAdd) {
      this.setState({selectedDepartments: [...this.state.selectedDepartments, this.state.departmentToAdd], departmentToAdd: null});
    }
  }

  public onPermissionChange = (e: any) => {
    const permissionLevel = e.target.value === "NONE" ? null : e.target.value;
    this.setState({selectedUserPermissions: permissionLevel});
  }

  public render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedDepartments } = this.state;
    const filteredDepartments: IDepartment[] =
      _.filter(this.props.departments, (department) => !_.find(selectedDepartments, (selectedDepartment) => selectedDepartment.id === department.id));
    const editUser: IUser = this.props.editUser;
    const initialPermissionLevel = this.props.editUser ? (editUser.permission ? editUser.permission : "NONE") : null;

    const isOkEnabled = this.state.validUserSelected;

    getFieldDecorator("departments", { initialValue: [] });
    const departmentList = (selectedDepartments != null && selectedDepartments.length > 0 ?
      <List dataSource={selectedDepartments}
            renderItem={(department: IDepartment) =>
              <List.Item actions={[<Button icon={"close-circle"} size="small"
                                           onClick={() => this.removeDepartment(department.id)}/>]}>
                {department.description}</List.Item>}>
      </List>
      : "");

    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };

    return (
      <Modal title={(this.props.editUser ? "EDIT USER" : "ADD NEW USER")}
             width={850}
             visible={this.props.addUserModalVisible}
             destroyOnClose={true}
             onOk={this.handleOk}
             onCancel={this.handleClose}
             afterClose={this.handleAfterClose}
             okButtonProps={{disabled: !isOkEnabled}}
      >
        <Form id="Form" layout="vertical" >
          <div style={{display: "flex", flexDirection: "column"}}>
            {(this.props.editUser ?
              <Form.Item label="Name / Email" style={{width: "100%"}}>
                <div>{editUser.firstName + " " + editUser.lastName + (editUser.email ? " / " +  editUser.email : "")}</div>
              </Form.Item> :
              <Form.Item label="Search" style={{width: "100%"}}>
                  {getFieldDecorator("user", {
                  rules: [{ required: true, message: "Please provide a user" }, { validator: this.checkUserExists}],
                })(
                  <AutoComplete
                    dataSource={this.props.cwoldUsers.map((u) => ({text: u.firstName + " "  + u.lastName.trim() + " - " + u.email, value: u.legacyUserId}))}
                    onSelect={this.cwoldUserSelect}
                    onSearch={this.cwoldUserSearch}
                  >
                  </AutoComplete>
                )}
              </Form.Item>
            )}
            <Form.Item label={(this.props.editUser ? "Departments" : "Department")} style={{width: "50%"}}>
              {departmentList}
              <Select value={(this.state.departmentToAdd ? this.state.departmentToAdd.id : null)}
                      showSearch
                      placeholder="Department"
                      onChange={this.onDepartmentChange}
                      optionFilterProp="name"
                      filterOption={(input, option) => {
                        if (option && option.props && option.props.children) {
                          return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        } else {
                          return false;
                        }
                      }
                      }
                      allowClear={true}
              >
                {filteredDepartments.map((department) => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.description}
                  </Select.Option>
                ))}
              </Select>
              <Button disabled={!this.state.departmentToAdd} onClick={this.addDepartment} style={{ position: "absolute", right: "0px", top: "30px"}}>
                <Icon type="plus" /> Add Department
              </Button>
            </Form.Item>
            <Form.Item label="Permission Level" style={{marginTop: 25}}>
              {getFieldDecorator("permissions", {
                initialValue: initialPermissionLevel
              })(
                <Radio.Group onChange={this.onPermissionChange}>
                  <Radio value="USER" style={radioStyle}>User</Radio>
                  <Radio value="EDITOR" style={radioStyle}>Editor</Radio>
                  <Radio value="APPROVER" style={radioStyle}>Approver</Radio>
                  <Radio value="NONE" style={radioStyle}>None</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps =  (state: ApplicationState): AddUserState => {
  const {app: appUtilityState, users: usersState, auth: authState} = state;

  return {
    authorizedUser: authState.authorizedUser,
    departments: appUtilityState.departments,
    cwoldUsers: usersState.cwoldUsers,
    users: usersState.users,
    lastSearch: usersState.lastSearch,
    editUser: usersState.selectedUser
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    saveUser: (user: IUser, lastSearch: IUserSearchCriteria) => dispatch(userActions.saveUser.request(user, lastSearch)),
    fetchCwolUsers: (searchCriteria: IUserSearchCriteria) => dispatch(userActions.getCwolUsers.request(searchCriteria)),
    fetchUsers: (searchCriteria: IUserSearchCriteria) => dispatch(userActions.getUsers.request(searchCriteria)),
    fetchUserDepartmentCounts: () => dispatch(appActions.getUserDepartmentCounts.request()),
    fetchUserPermissionLevelCounts: () => dispatch(appActions.getUserPermissionLevelCounts.request()),
    selectUser: (user: IUser | undefined) => dispatch(userActions.selectUser.select(user))
  };
};

interface ConnectedAddUserModalDispatchProps extends UsersLocalState {
  addUserModalVisible: boolean;
  setVisible: (visible: boolean) => any;
}

interface DispatchProps {
  saveUser: (user: IUser, lastSearch: IUserSearchCriteria) => any;
  fetchCwolUsers: (searchCriteria: IUserSearchCriteria) => any;
  fetchUsers: (searchCriteria: IUserSearchCriteria) => any;
  fetchUserDepartmentCounts: () => any;
  fetchUserPermissionLevelCounts: () => any;
  selectUser: (user: IUser | undefined) => any;
}

const ConnectedAddUserModal: React.ComponentClass<ConnectedAddUserModalDispatchProps> =
  connect<AddUserState, DispatchProps, ConnectedAddUserModalDispatchProps, ApplicationState>(mapStateToProps, mapDispatchToProps)(Form.create()(AddUserModal));

export default ConnectedAddUserModal;
