import {connectRouter} from "connected-react-router";
import {History} from "history";
import {combineReducers} from "redux";
import {ApplicationState} from "../types";
import {appReducer, initialState as appInitialState} from "./app";
import {authReducer, initialState as authInitialState} from "./auth";
import {documentReducer, initialState as documentInitialState} from "./documents";
import {homeReducer, initialState as homeInitialState} from "./home";
import {initialState as userInitialState, userReducer} from "./user";

export const initialState: ApplicationState = {
  auth: authInitialState,
  app: appInitialState,
  home: homeInitialState,
  documents: documentInitialState,
  users: userInitialState,
};

const reducer = (history: History) => combineReducers<ApplicationState>({
  auth: authReducer,
  app: appReducer,
  home: homeReducer,
  documents: documentReducer,
  router: connectRouter(history),
  users: userReducer
});

export default reducer;
export {reducer as rootReducer};
