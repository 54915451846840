import React from "react";
import SideBar from "../components/SideBar";
import withDefaultLayout from "./DefaultLayout";

const withHomeLayout = (): <P extends object>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<P> => {

  return withDefaultLayout(SideBar);
};

export default withHomeLayout();
