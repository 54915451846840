import {Button, Icon, Table, Tooltip} from "antd";
import {ColumnProps} from "antd/lib/table";
import moment from "moment";
import {Component} from "react";
import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {DOCUMENT_STATUS} from "../config/constants";
import {actions as documentActions} from "../redux/documents";
import {IDocument, ISearchCriteria} from "../types/server";
import ApproveDocumentModal from "./ApproveDocumentModal";
import ReviewDocumentModal from "./ReviewDocumentModal";
import ReviseDocumentModal from "./ReviseDocumentModal";

interface DocumentTableProps {
  fetching: boolean;
  documents: any[];
  userCanEdit: boolean;
  userCanApprove: boolean;
  error?: string;
}

interface DispatchProps {
  selectDocument: (documentId: number) => any;
  updateDocumentAsReviewed: (documentReview: {documentVersionId: number, reviewDate: Date, reviewUserId: number}) => any;
  approveDocument: (documentApproval: { documentId: number, documentVersionId: number, approvalDate: Date, approvalUserId: number, effectiveDate: Date }, lastSearch: ISearchCriteria) => any;
}

interface Props extends DocumentTableProps, DispatchProps {}

interface DocumentTableLocalState {
  confirmReviewModalVisible: boolean;
  confirmApproveModalVisible: boolean;
  reviseDocumentVisible: boolean;
  selectedDocument?: IDocument;
}

class DocumentTable extends Component<Props, DocumentTableLocalState> {

  constructor(props: any) {
      super(props);
      this.state = {confirmReviewModalVisible: false, confirmApproveModalVisible: false, reviseDocumentVisible: false};
      this.setConfirmReviewModalVisible = this.setConfirmReviewModalVisible.bind(this);
      this.setConfirmApproveModalVisible = this.setConfirmApproveModalVisible.bind(this);
  }

  public setConfirmReviewModalVisible(confirmReviewModalVisible: boolean) {
    this.setState({confirmReviewModalVisible});
  }

  public handleReviewClick(document: IDocument) {
    this.setState({confirmReviewModalVisible: true, selectedDocument: document});
  }

  public setConfirmApproveModalVisible(confirmApproveModalVisible: boolean) {
    this.setState({confirmApproveModalVisible});
  }

  public handleApproveClick(document: IDocument) {
    this.setState({confirmApproveModalVisible: true, selectedDocument: document});
  }

  public setReviseDocumentModalVisible = (reviseDocumentVisible: boolean) => {
    this.setState({reviseDocumentVisible});
  }

  public render() {
    const {fetching, documents, userCanEdit, userCanApprove} = this.props;

    if (fetching) {
      const divStyle = {width: "100%", height: "75%", display: "flex", alignItems: "center", justifyContent: "center"};
      return <div style={divStyle}><Button size="large" shape="circle" loading/></div>;
    }

    const documentList = documents.map((item) => Object.assign(item, {key: item.id}));

    const quickActionsRenderer = (text: any, record: any, index: any) => {

      const document: IDocument = record;
      const style = {width: "100px"};

      if (document.status === DOCUMENT_STATUS.UNDER_REVISION && (userCanApprove)) {
        return <Button type="primary" style={style} onClick={() => this.handleApproveClick(record)}>Approve</Button>;
      } else if ((userCanApprove || userCanEdit) && document.reviewDueInDays && document.reviewDueInDays <= 90) {
        return <Button type="primary" style={style} onClick={() => this.handleReviewClick(record)}>Review</Button>;
      } else if (userCanApprove || userCanEdit) {
        return <Button type="primary" style={style} onClick={() => this.setReviseDocumentModalVisible(true)}>Revise</Button>;
      }
    };

    const reviewDueRenderer = (text: any, record: any, index: any) => {

      const document: IDocument = record;
      if (document.reviewDueInDays && userCanEdit) {
        const reviewDueDate: Date = moment().add(document.reviewDueInDays, "days").toDate();
        const reviewDueText: string = (document.reviewDueInDays > 0 ?
          "Review is due " + moment(reviewDueDate).format("MMM-D-YYYY") :
          "Review was due " + moment(reviewDueDate).fromNow());
        if (document.reviewDueInDays && (document.reviewDueInDays < 30)) {
          return <Tooltip title={reviewDueText}><Icon type="calendar" style={{color: "red"}}/></Tooltip>;
        } else if (userCanApprove || userCanEdit) {
          return <div/>;
        }
      } else {
        return <div/>;
      }
    };

    const columns: Array<ColumnProps<IDocument>> = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        defaultSortOrder: "ascend",
        sorter: (a: IDocument, b: IDocument) => a.title.localeCompare(b.title),
        width: 500
      },
      {
        title: "Effective Date",
        dataIndex: "effectiveDate",
        key: "effectiveDate",
        render: (d: Date) => d ? moment(d).format("YYYY-MM-DD") : null,
        sorter: (a: IDocument, b: IDocument) => {
          if (a.effectiveDate && b.effectiveDate) {
            if (a.effectiveDate > b.effectiveDate) {
              return 1;
            } else if (a.effectiveDate < b.effectiveDate) {
              return -1;
            } else {
              return 0;
            }
          } else {
            if (a.effectiveDate) {
              return 1;
            } else if (b.effectiveDate) {
              return -1;
            } else {
              return 0;
            }
          }
        },
        width: 150
      },
      {
        title: "Type",
        dataIndex: "documentType",
        key: "documentType",
        sorter: (a: IDocument, b: IDocument) => a.documentType.localeCompare(b.documentType),
        width: 150
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a: IDocument, b: IDocument) => {
          if (a.status && b.status) {
            return a.status.localeCompare(b.status);
          } else {
            return 0;
          }
        },
        width: 150
      },
      {
        title: userCanEdit ? "Quick Actions" : "",
        render: quickActionsRenderer,
        width: userCanEdit ? 180 : 0
      },
      {
        dataIndex: "reviewDueInDays",
        key: "reviewDueInDays",
        render: reviewDueRenderer
      }
    ];

    const onRowClick = (record: any, rowIndex: any) => {
      return {
        onClick: (event: any) => {
          this.setState({selectedDocument: record});
          this.props.selectDocument(record.id);
        }
      };
    };

    const setRowClassName = (record: IDocument) => {
      if (this.state.selectedDocument) {
        return record.id === this.state.selectedDocument.id ? "row-selected" : "";
      } else {
        return "";
      }
    };

    return (
      <div>
        <div id="DocumentTable">
          <Table<IDocument> onRow={onRowClick}
                            dataSource={documentList}
                            columns={columns}
                            rowClassName={setRowClassName}/>
        </div>
        <ReviewDocumentModal confirmReviewModalVisible={this.state.confirmReviewModalVisible}
                             document={this.state.selectedDocument}
                             setVisible={this.setConfirmReviewModalVisible}/>
        <ApproveDocumentModal confirmApproveModalVisible={this.state.confirmApproveModalVisible}
                             document={this.state.selectedDocument}
                             setVisible={this.setConfirmApproveModalVisible}/>
        <ReviseDocumentModal setVisible={this.setReviseDocumentModalVisible}
                             reviseDocumentModalVisible={this.state.reviseDocumentVisible}/>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    selectDocument: (documentId: number) => dispatch(documentActions.getDocumentById.request(documentId)),
    updateDocumentAsReviewed: (documentReview) => dispatch(documentActions.updateDocumentAsReviewed.request(documentReview)),
    approveDocument: (documentApproval, lastSearch) => dispatch(documentActions.approveDocument.request(documentApproval, lastSearch)),
  };
};

export default connect(null, mapDispatchToProps)(DocumentTable);
