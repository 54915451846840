import {Modal} from "antd";
import {Component} from "react";
import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as documentActions} from "../redux/documents";
import {ApplicationState} from "../types";
import {IDocument, IUser} from "../types/server";

interface ReviewDocumentModalProps {
  document: IDocument | undefined;
  confirmReviewModalVisible: boolean;
  setVisible: (visible: boolean) => any;
}

interface DispatchProps {
  updateDocumentAsReviewed: (documentReview: {documentVersionId: number, reviewDate: Date, reviewUserId: number}) => any;
}

interface ReviewDocumentState {
  authorizedUser: IUser;
}

interface Props extends ReviewDocumentModalProps, ReviewDocumentState, DispatchProps {}

class ReviewDocumentModal extends Component<Props, {}> {

  public handleOk = () => {
    const document = this.props.document;
    if (document && document.id && this.props.authorizedUser.id) {
      this.props.updateDocumentAsReviewed({documentVersionId: document.documentVersionId, reviewDate: new Date(), reviewUserId: this.props.authorizedUser.id});
      this.props.setVisible(false);
    }
  }

  public handleClose = () => this.props.setVisible(false);

  public render() {
    return (
      <Modal
        title="Mark As Reviewed?"
        visible={this.props.confirmReviewModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleClose}
      >
        <p>Please confirm that the document has been reviewed.</p>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReviewDocumentState => {

  const {auth: authState} = state;

  return {
    authorizedUser: authState.authorizedUser
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    updateDocumentAsReviewed: (documentReview: {documentVersionId: number, reviewDate: Date, reviewUserId: number}) => dispatch(documentActions.updateDocumentAsReviewed.request(documentReview))
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (ReviewDocumentModal);
