import axios from "axios";
import {AnyAction} from "redux";
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {actions as homeActions, types as homeActionTypes} from "../redux/home";
import {IUser} from "../types/server";

function fetchAssignedDocuments(user: IUser) {
  return axios({
    method: "post",
    url: "/api/assigned",
    data: user
  });
}

function fetchRecentDocuments() {
  return axios({
    method: "get",
    url: "/api/recent"
  });
}

export function* watchFetchAssignedDocuments() {
  yield takeEvery(homeActionTypes.GET_ASSIGNED_DOCUMENTS_REQUEST, handleFetchAssignedDocuments);
}

export function* watchFetchRecentDocuments() {
  yield takeEvery(homeActionTypes.GET_RECENT_DOCUMENTS_REQUEST, handleFetchRecentDocuments);
}

function* handleFetchAssignedDocuments(action: AnyAction) {
  try {
    const response = yield call(fetchAssignedDocuments, action.payload.user);

    if (response.error) {
      yield put(homeActions.getAssignedDocuments.failure(response.error));
    } else {
      yield put(homeActions.getAssignedDocuments.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(homeActions.getAssignedDocuments.failure(error.stack!));
    } else {
      yield put(homeActions.getAssignedDocuments.failure("An unknown error occurred while fetching documents assigned to current user."));
    }
  }
}

function* handleFetchRecentDocuments(action: AnyAction) {
  try {
    const response = yield call(fetchRecentDocuments);

    if (response.error) {
      yield put(homeActions.getRecentDocuments.failure(response.error));
    } else {
      yield put(homeActions.getRecentDocuments.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(homeActions.getRecentDocuments.failure(error.stack!));
    } else {
      yield put(homeActions.getRecentDocuments.failure("An unknown error occurred while fetching recently updated documents."));
    }
  }
}

export function* homeSaga() {
  yield all([fork(watchFetchAssignedDocuments)]);
  yield all([fork(watchFetchRecentDocuments)]);
}
