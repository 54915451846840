import axios from "axios";
import { push } from "connected-react-router";
import {AnyAction} from "redux";
import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {actions as authActions, types as authActionTypes} from "../redux/auth";

function login(username: string, password: string) {
  return axios({
    method: "get",
    url: "/api/auth",
    params: {
      username,
      password
    }
  });
}

function logout(userId: string) {
  return axios({
    method: "get",
    url: "/api/revokeAuthByUser",
    params: {
      userId
    }
  });
}

export function* watchLogin() {
  yield takeLatest(authActionTypes.LOGIN_REQUEST, handleLogin);
}

export function* watchLogout() {
  yield takeLatest(authActionTypes.LOGOUT_REQUEST, handleLogout);
}

function* handleLogin(action: AnyAction) {
  try {
    const response = yield call(login, action.payload.username, action.payload.password);

    if (response.error) {
      yield put(authActions.login.failure(response.error));
    } else {
      yield put(authActions.login.success(response));
      yield localStorage.setItem("accessToken", response.headers["access-token"]);
      yield put(push("/home"));
    }
  } catch (error) {
    console.log("error");
    if (error instanceof Error) {
      yield put(authActions.login.failure(error.stack!));
    } else {
      yield put(authActions.login.failure("An unknown error occurred while authenticating the user."));
    }
  }
}

function* handleLogout(action: AnyAction) {
  try {
    yield localStorage.clear();
    const response = yield call(logout, action.payload.userId);

    if (response.error) {
      yield put(authActions.login.failure(response.error));
    } else {
      yield put(authActions.login.success(response));
      yield put(push("/login"));
    }
  } catch (error) {
    console.log("error");
    if (error instanceof Error) {
      yield put(authActions.login.failure(error.stack!));
    } else {
      yield put(authActions.login.failure("An unknown error occurred while authenticating the user."));
    }
  }
}

export function* authSaga() {
  yield all([fork(watchLogin)]);
  yield all([fork(watchLogout)]);
}
