import React from "react";
import {Redirect, Route, RouteComponentProps, RouteProps} from "react-router";
import {IUser} from "../types/server";

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  authorizedUser: IUser;
}

const PrivateRoute = (props: PrivateRouteProps) => {

  const {component: Component, ...rest} = props;

  // TODO write a select that determines if the user is authenticated or not.
  // TODO right now we'll just check to see if the token is set.  We MUST check that it's valid or plugin some middleware
  //      that will automatically put the token in the subsequent request and check the response, if 401 is returned, we
  //      need to make sure the token is cleared out of localStorage and the user is redirected to here (Login).
  // TODO add a logout function that clears localStorage
  const token = localStorage.getItem("accessToken");
  if (!token) {
    // const from = props.location || "/";
    // return <Redirect to={from} />
    return <Redirect to="/login" />;
  }

  return (
    <Route {...rest} render={(props) => (
        <Component {...props} />
    )}/>
  );
};

export default PrivateRoute;
