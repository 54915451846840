import {Layout} from "antd";
import React, { Component } from "react";
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router";
import {Dispatch} from "redux";
import "./App.css";
import withDocumentsLayout from "./layout/DocumentsLayout";
import withEmptyLayout from "./layout/EmptyLayout";
import withHomeLayout from "./layout/HomeLayout";
import withUsersLayout from "./layout/UsersLayout";
import {actions as appActions} from "./redux/app";
import {actions as documentActions} from "./redux/documents";
import PrivateRoute from "./router/PrivateRoute";
import {ApplicationState} from "./types";
import {IDocument, IUser} from "./types/server";
import Documents from "./views/Documents";
import Home from "./views/Home";
import Login from "./views/Login";
import Users from "./views/Users";

interface DispatchProps {
  selectDocument: (document: IDocument) => any;
  fetchDepartments: () => any;
}

interface AppLocalState {
  authorizedUser: IUser;
}

interface Props extends AppLocalState, DispatchProps {}

class App extends Component<Props, {}> {

  public componentDidMount(): void {
    this.props.fetchDepartments();
  }

  public componentWillUpdate(nextProps: any) {
    nextProps.history.listen((location: any, action: any) => {
      nextProps.selectDocument(null);
    });
  }

  public render() {

    return (
      <Layout id="App" style={{width: "95%"}}>
        <Switch>
          <Route exact path="/login" component={withEmptyLayout(Login)}/>
          <PrivateRoute exact path="/" authorizedUser={this.props.authorizedUser} component={withHomeLayout(Home)}/>
          <PrivateRoute path="/home" authorizedUser={this.props.authorizedUser} component={withHomeLayout(Home)}/>
          <PrivateRoute path="/documents" authorizedUser={this.props.authorizedUser} component={withDocumentsLayout(Documents)}/>
          <PrivateRoute path="/user" authorizedUser={this.props.authorizedUser} component={withUsersLayout(Users)}/>
          <Route render={(props) => <div>404</div>}/>
        </Switch>
      </Layout>
    );
  }
}

const mapStateToProps = (state: ApplicationState): any => {
  return {
    authorizedUser: state.auth.authorizedUser
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    selectDocument: (document: IDocument) => dispatch(documentActions.selectDocument.select(document)),
    fetchDepartments: () => dispatch(appActions.getDepartments.request()),
  };
};

export default withRouter<any>(connect(mapStateToProps, mapDispatchToProps)(App));
