import {Button, Col, List, Row} from "antd";
import moment from "moment";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ISearchCriteria} from "../../../src/types/cwpolicy";
import AddDocumentModal from "../components/AddDocumentModal";
import DocumentTable from "../components/DocumentTable";
import {actions as documentActions} from "../redux/documents";
import {ApplicationState, DocumentLocalState, DocumentState} from "../types";

interface DispatchProps {
  fetchDocuments: (searchCriteria: ISearchCriteria) => any;
  fetchAllDocuments: (searchCriteria: ISearchCriteria) => any;
}

interface Props extends DocumentState, DispatchProps {}

class Documents extends Component<Props, DocumentLocalState> {

  constructor(props: any) {
      super(props);
      this.state = {addDocumentModalVisible: false};
      this.setAddDocumentModalVisible = this.setAddDocumentModalVisible.bind(this);
  }

  public componentDidMount() {
      if (this.props.allDocuments === null || this.props.allDocuments.length === 0) {
        this.props.fetchDocuments({excludeArchived: true});
        this.props.fetchAllDocuments({});
      }
  }

  public setAddDocumentModalVisible(addDocumentModalVisible: boolean) {
    this.setState({addDocumentModalVisible});
  }

  public render() {

    const {fetching, documents} = this.props;

    const userCanApprove: boolean = (this.props.authorizedUser.canApprove ? this.props.authorizedUser.canApprove : false);
    const userCanEdit: boolean = (this.props.authorizedUser.canEdit ? this.props.authorizedUser.canEdit : false);

    const documentSearchCriteriaElement = () => {
      const search = this.props.lastSearch;
      const allDocuments: any = <p style={{marginLeft: "20px", top: "10px", fontSize: "20px", fontWeight: "bold"}}>ALL DOCUMENTS</p>;
      if (!search) {
        return allDocuments;
      }

      const criteria: string[] = [];
      if (search.searchText && search.searchText.length > 0) {
        criteria.push("Title contains '" + search.searchText + "'");
      }
      if (search.effectiveDateStart && search.effectiveDateEnd) {
        criteria.push("Effective date between " + moment(search.effectiveDateStart).format("YYYY-MM-DD") +
          " and " + moment(search.effectiveDateEnd).format("YYYY-MM-DD"));
      }
      if (search.reviewedDateStart && search.reviewedDateEnd) {
        criteria.push("Review date between " + moment(search.reviewedDateStart).format("YYYY-MM-DD") +
          " and " + moment(search.reviewedDateEnd).format("YYYY-MM-DD"));
      }
      if (search.reviewDueInDays) {
        criteria.push("Review due in " + search.reviewDueInDays.toString() + " days or fewer");
      }
      if (search.department) {
        criteria.push("Department: " + search.department);
      }
      if (search.category) {
        criteria.push("Category: " + search.category);
      }
      if (search.documentType) {
        criteria.push("Document Type: " + search.documentType);
      }
      if (search.status) {
        criteria.push("Status: " + search.status);
      }
      if (!search.excludeArchived) {
        criteria.push("Include Archived documents");
      }

      if (criteria.length === 0) {
        return allDocuments;
      } else {
        return (
          <div>
          <span style={{marginLeft: "20px", top: "10px", fontSize: "14px", fontWeight: "bold"}}>Documents matching criteria:</span>
          <List style={{marginLeft: "20px", bottom: "5px", fontSize: "14px"}}
                size={"small"} dataSource={criteria}
                renderItem={(item) => <List.Item>{item}</List.Item>}/>
          </div>
        );
      }
    };

    const addDocumentButton = () => {
      if (this.props.authorizedUser.canEdit) {
        return (
          <Button type="primary" onClick={() => this.setAddDocumentModalVisible(true)}>+ Add Document</Button>
        );
      } else {
        return <div/>;
      }
    };

    return (
      <div style={{ height: "100%"}}>
        <Row style={{height: "50px"}} >
          <Col span={19} style={{top: "9px"}}>
            {documentSearchCriteriaElement()}
          </Col>
          <Col span={5} style={{top: "9px", paddingLeft: "22px"}}>
            {addDocumentButton()}
          </Col>
        </Row>
        <DocumentTable fetching={fetching} documents={documents} userCanEdit={userCanEdit} userCanApprove={userCanApprove}/>
        <AddDocumentModal addDocumentModalVisible={this.state.addDocumentModalVisible} setVisible={this.setAddDocumentModalVisible}/>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): DocumentState => {

  const {documents: documentState, auth: authState} = state;

  return {
    fetching: documentState.fetching,
    documents: documentState.documents,
    error: documentState.error,
    fetchingAll: documentState.fetchingAll,
    allDocuments: documentState.allDocuments,
    errorAll: documentState.errorAll,
    addDocumentModalVisible: documentState.addDocumentModalVisible,
    authorizedUser: authState.authorizedUser,
    lastSearch: documentState.lastSearch
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchDocuments: (searchCriteria: ISearchCriteria) => dispatch(documentActions.getDocuments.request(searchCriteria)),
    fetchAllDocuments: (searchCriteria: ISearchCriteria) => dispatch(documentActions.getAllDocuments.request(searchCriteria))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
