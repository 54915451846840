import {AnyAction, Reducer} from "redux";
import {action} from "typesafe-actions";
import {IUserSearchCriteria} from "../../../src/types/cwpolicy";
import {IUser, UserState} from "../types";

export const types = {
  GET_USERS_REQUEST: "USER/GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "USER/GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "USER/GET_USERS_FAILURE",
  GET_CWOL_USERS_REQUEST: "USER/GET_CWOL_USERS_REQUEST",
  GET_CWOL_USERS_SUCCESS: "USER/GET_CWOL_USERS_SUCCESS",
  GET_CWOL_USERS_FAILURE: "USER/GET_CWOL_USERS_FAILURE",
  GET_ALL_USERS_REQUEST: "USER/GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_SUCCESS: "USER/GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "USER/GET_ALL_USERS_FAILURE",
  GET_USER_REQUEST: "USER/GET_USER_REQUEST",
  GET_USER_SUCCESS: "USER/GET_USER_SUCCESS",
  GET_USER_FAILURE: "USER/GET_USER_FAILURE",
  GET_USER_POST_SAVE_REQUEST: "USER/GET_USER_POST_SAVE_REQUEST",
  GET_USER_POST_SAVE_SUCCESS: "USER/GET_USER_POST_SAVE_SUCCESS",
  GET_USER_POST_SAVE_FAILURE: "USER/GET_USER_POST_SAVE_FAILURE",
  SAVE_USER_REQUEST: "USER/SAVE_USER_REQUEST",
  SAVE_USER_SUCCESS: "USER/SAVE_USER_SUCCESS",
  SAVE_USER_FAILURE: "USER/SAVE_USER_FAILURE",
  SELECT_USER: "USER/SELECT_USER"
};

export const initialState: UserState = {
  fetching: false,
  users: [],
  error: "",
  fetchingCwold: false,
  cwoldUsers: [],
  errorCwold: "",
  fetchingAll: false,
  allUsers: [],
  errorAll: "",
  departments: [],
  userPermissions: [],
  addUserModalVisible: false,
  authorizedUser: null
};

export const reducer: Reducer<UserState> = (state: UserState = initialState, action: AnyAction) => {

  switch (action.type) {
    case types.GET_USERS_REQUEST:
      return {...state, fetching: true, error: "", lastSearch: action.payload.searchCriteria};
    case types.GET_USERS_SUCCESS:
      return {...state, fetching: false, users: action.payload.data};
    case types.GET_USERS_FAILURE:
      return {...state, fetching: false, users: [], error: action.payload.data};
    case types.GET_CWOL_USERS_REQUEST:
      return {...state, fetchingCwold: true, error: ""};
    case types.GET_CWOL_USERS_SUCCESS:
      return {...state, fetchingCwold: false, cwoldUsers: action.payload.data};
    case types.GET_CWOL_USERS_FAILURE:
      return {...state, fetchingCwold: false, cwoldUsers: [], error: action.payload.data};
    case types.GET_ALL_USERS_REQUEST:
      return {...state, fetchingAll: true, errorCwold: ""};
    case types.GET_ALL_USERS_SUCCESS:
      return {...state, fetchingAll: false, allUsers: action.payload.data};
    case types.GET_ALL_USERS_FAILURE:
      return {...state, fetchingAll: false, allUsers: [], errorAll: action.payload.data};
    case types.GET_USER_REQUEST:
      return {...state};
    case types.GET_USER_SUCCESS:
      return {...state, fetching: false, selectedUser: action.payload.data};
    case types.GET_USER_FAILURE:
      return {...state, fetching: false, selectedUser: null, error: action.payload.data};
    case types.GET_USER_POST_SAVE_REQUEST:
      return {...state};
    case types.GET_USER_POST_SAVE_SUCCESS:
      return {...state, fetching: false, selectedUser: action.payload.data};
    case types.GET_USER_POST_SAVE_FAILURE:
      return {...state, fetching: false, selectedUser: null, error: action.payload.data};
    case types.SAVE_USER_REQUEST:
      return {...state};
    case types.SAVE_USER_SUCCESS:
      return {...state};
    case types.SAVE_USER_FAILURE:
      return {...state, error: action.payload.data};
    case types.SELECT_USER:
      return {...state, selectedUser: action.payload.user};

    default:
      return state;
  }
};

export const getUsers = {
  request: (userSearchCriteria: IUserSearchCriteria) => action(types.GET_USERS_REQUEST, {searchCriteria: userSearchCriteria}),
  success: (data: IUser[]) => action(types.GET_USERS_SUCCESS, data),
  failure: (error: string) => action(types.GET_USERS_FAILURE, error)
};

export const getCwolUsers = {
  request: (userSearchCriteria: IUserSearchCriteria) => action(types.GET_CWOL_USERS_REQUEST, {searchCriteria: userSearchCriteria}),
  success: (data: IUser[]) => action(types.GET_CWOL_USERS_SUCCESS, data),
  failure: (error: string) => action(types.GET_CWOL_USERS_FAILURE, error)
};

export const getAllUsers = {
  request: (userSearchCriteria: IUserSearchCriteria) => action(types.GET_ALL_USERS_REQUEST, {searchCriteria: userSearchCriteria}),
  success: (data: IUser[]) => action(types.GET_ALL_USERS_SUCCESS, data),
  failure: (error: string) => action(types.GET_ALL_USERS_FAILURE, error)
};

export const getUser = {
  request: (id: number) => action(types.GET_USER_REQUEST, {id}),
  success: (data: IUser) => action(types.GET_USER_SUCCESS, data),
  failure: (error: string) => action(types.GET_USER_FAILURE, error)
};

export const getUserPostSave = {
  request: (id: number) => action(types.GET_USER_POST_SAVE_REQUEST, {id}),
  success: (data: IUser) => action(types.GET_USER_POST_SAVE_SUCCESS, data),
  failure: (error: string) => action(types.GET_USER_POST_SAVE_FAILURE, error)
};

export const saveUser = {
  request: (user: IUser, lastSearch: IUserSearchCriteria) => action(types.SAVE_USER_REQUEST, {user, lastSearch}),
  success: (user: IUser, lastSearch: IUserSearchCriteria) => action(types.SAVE_USER_SUCCESS, {user, lastSearch}),
  failure: (error: string) => action(types.SAVE_USER_FAILURE, error)
};

export const selectUser = {
  select: (user: IUser) => action(types.SELECT_USER, {user}),
};

export const actions = {
  getUsers,
  getCwolUsers,
  getAllUsers,
  getUser,
  getUserPostSave,
  saveUser,
  selectUser
};

export default reducer;
export {reducer as userReducer};
