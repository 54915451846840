import {Button, Col, List, Row} from "antd";
import _ from "lodash";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {IUserSearchCriteria} from "../../../src/types/cwpolicy";
import AddUserModal from "../components/AddUserModal";
import UsersTable from "../components/UsersTable";
import {actions as userActions} from "../redux/user";
import {ApplicationState, UsersLocalState, UserState} from "../types";

interface DispatchProps {
  fetchUsers: (userSearchCriteria: IUserSearchCriteria) => any;
}

interface Props extends UserState, DispatchProps {}

class Users extends Component<Props, UsersLocalState> {

  constructor(props: any) {
      super(props);
      this.state = {
        addUserModalVisible: false
      };
      this.setAddUserModalVisible = this.setAddUserModalVisible.bind(this);
  }

  public componentDidMount() {
    if (this.props.users === null || this.props.users.length === 0) {
      this.props.fetchUsers({});
    }
  }

  public setAddUserModalVisible(addUserModalVisible: boolean) {
    this.setState({addUserModalVisible});
  }

  public render() {

    const {fetching, users} = this.props;

    const userCanApprove: boolean = (this.props.authorizedUser.canApprove ? this.props.authorizedUser.canApprove : false);
    const userCanEdit: boolean = (this.props.authorizedUser.canApprove ? this.props.authorizedUser.canApprove : false);

    const userSearchCriteriaElement = () => {
      const search = this.props.lastSearch;
      const allUsers: any = <p style={{marginLeft: "20px", top: "10px", fontSize: "20px", fontWeight: "bold"}}>ALL USERS</p>;

      if (!search) {
        return allUsers;
      }

      const criteria: string[] = [];
      if (search.searchString && search.searchString.length > 0) {
        criteria.push("Search contains '" + search.searchString + "'");
      }
      if (search.department && search.department.length > 0) {
        criteria.push("Department: " + search.department);
      }
      if (search.permissionLevel && search.permissionLevel.length > 0) {
        criteria.push("Permission Level: " + _.capitalize(search.permissionLevel));
      }

      if (criteria.length === 0) {
        return allUsers;
      } else {
        return (
          <div>
            <span style={{marginLeft: "20px", top: "10px", fontSize: "14px", fontWeight: "bold"}}>Users matching search criteria:</span>
            <List style={{marginLeft: "20px", bottom: "5px", fontSize: "14px"}}
                  size={"small"} dataSource={criteria}
                  renderItem={(item) => <List.Item>{item}</List.Item>}/>
          </div>
        );
      }

    };

    const addUserButton = () => {
      if (this.props.authorizedUser.canEdit) {
        return (
          <Button type="primary" onClick={() => this.setAddUserModalVisible(true)}>+ Add USER</Button>
        );
      } else {
        return <div/>;
      }
    };

    return (
      <div style={{ height: "100%"}}>
        <Row style={{height: "50px"}} >
          <Col span={19} style={{top: "9px"}}>
            {userSearchCriteriaElement()}
          </Col>
          <Col span={5} style={{top: "9px", paddingLeft: "22px"}}>
            {addUserButton()}
          </Col>
        </Row>
        <UsersTable fetching={fetching} users={users} userCanEdit={userCanEdit} userCanApprove={userCanApprove}/>
        <AddUserModal addUserModalVisible={this.state.addUserModalVisible} setVisible={this.setAddUserModalVisible}/>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): any => {

  const {users: userState, auth: authState} = state;

  return {
    fetching: userState.fetching,
    users: userState.users,
    error: userState.error,
    addUserModalVisible: userState.addUserModalVisible,
    authorizedUser: authState.authorizedUser,
    lastSearch: userState.lastSearch
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchUsers: (userSearchCriteria: IUserSearchCriteria) => dispatch(userActions.getUsers.request(userSearchCriteria))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
