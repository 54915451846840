import {all, fork} from "redux-saga/effects";
import {appSaga} from "./app-sagas";
import {authSaga} from "./auth-sagas";
import {documentSaga} from "./document-sagas";
import {homeSaga} from "./home-sagas";
import {userSaga} from "./user-sagas";

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(appSaga),
    fork(homeSaga),
    fork(documentSaga),
    fork(userSaga)
  ]);
}
