import axios from "axios";
import {AnyAction} from "redux";
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {ICwolDbUser} from "../../../src/types/cwoldb";
import {IUserSearchCriteria} from "../../../src/types/cwpolicy";
import {actions as appActions} from "../redux/app";
import {actions as userActions, types as userActionTypes} from "../redux/user";
import {IUser} from "../types/server";

function fetchUsers(searchCriteria: IUserSearchCriteria) {
  return axios({
    method: "post",
    url: "/api/users",
    data: searchCriteria
  });
}

function fetchCwolUsers(searchCriteria: ICwolDbUser) {
  return axios({
    method: "post",
    url: "/api/users/cwolSearch",
    data: searchCriteria
  });
}

function getUser(id: number) {
  return axios({
    method: "get",
    url: "/api/users/id",
    params: {
      data: id
    }
  });
}

function saveUser(user: IUser) {
  return axios({
    method: "post",
    url: "/api/users/save",
    data: user
  });
}

export function* watchFetchUsers() {
  yield takeEvery(userActionTypes.GET_USERS_REQUEST, handleFetchUsers);
}

export function* watchFetchCwolUsers() {
  yield takeEvery(userActionTypes.GET_CWOL_USERS_REQUEST, handleFetchCwolUsers);
}

export function* watchFetchAllUsers() {
  yield takeEvery(userActionTypes.GET_ALL_USERS_REQUEST, handleFetchAllUsers);
}

export function* watchGetUser() {
  yield takeEvery(userActionTypes.GET_USER_REQUEST, handleGetUser);
}

export function* watchSaveUser() {
  yield takeEvery(userActionTypes.SAVE_USER_REQUEST, handleSaveUser);
}

export function* watchSaveUserSuccess() {
  yield takeEvery(userActionTypes.SAVE_USER_SUCCESS, handleSaveUserSuccess);
}

function* handleFetchUsers(action: AnyAction) {
  try {
    const response = yield call(fetchUsers, action.payload.searchCriteria);

    if (response.error) {
      yield put(userActions.getUsers.failure(response.payload.searchCriteria));
    } else {
      yield put(userActions.getUsers.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(userActions.getUsers.failure(error.stack!));
    } else {
      yield put(userActions.getUsers.failure("An unknown error occurred while fetching the users."));
    }
  }
}

function* handleFetchCwolUsers(action: AnyAction) {
  try {
    const response = yield call(fetchCwolUsers, action.payload.searchCriteria);

    if (response.error) {
      yield put(userActions.getCwolUsers.failure(response.payload.searchCriteria));
    } else {
      yield put(userActions.getCwolUsers.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(userActions.getCwolUsers.failure(error.stack!));
    } else {
      yield put(userActions.getCwolUsers.failure("An unknown error occurred while fetching the CarolWoods users."));
    }
  }
}

function* handleFetchAllUsers(action: AnyAction) {
  try {
    const response = yield call(fetchUsers, action.payload.searchCriteria);

    if (response.error) {
      yield put(userActions.getAllUsers.failure(response.error));
    } else {
      yield put(userActions.getAllUsers.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(userActions.getAllUsers.failure(error.stack!));
    } else {
      yield put(userActions.getAllUsers.failure("An unknown error occurred while fetching all users."));
    }
  }
}

function* handleGetUser(action: AnyAction) {
  try {
    const response = yield call(getUser, action.payload.id);

    if (response.error) {
      yield put(userActions.getUser.failure(response.error));
    } else {
      yield put(userActions.getUser.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(userActions.getUser.failure(error.stack!));
    } else {
      yield put(userActions.getUser.failure("An unknown error occurred while getting the user."));
    }
  }
}

function* handleSaveUser(action: AnyAction) {
  try {
    const response = yield call(saveUser, action.payload.user);

    if (response.error) {
      yield put(userActions.saveUser.failure(response.error));
    } else {
      yield put(userActions.saveUser.success(response.data, action.payload.lastSearch));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(userActions.saveUser.failure(error.stack!));
    } else {
      yield put(userActions.saveUser.failure("An unknown error occurred while saving the user."));
    }
  }
}

function* handleSaveUserSuccess(action: AnyAction) {
  try {
    yield put(userActions.getUsers.request(action.payload.lastSearch));
    yield put(appActions.getUserDepartmentCounts.request());
    yield put(appActions.getUserPermissionLevelCounts.request());

  } catch (error) {
    if (error instanceof Error) {
      yield put(userActions.getUserPostSave.failure(error.stack!));
    } else {
      yield put(userActions.getUserPostSave.failure("An unknown error occurred while retrieving the document."));
    }
  }
}

export function* userSaga() {
  yield all([fork(watchFetchUsers)]);
  yield all([fork(watchFetchCwolUsers)]);
  yield all([fork(watchFetchAllUsers)]);
  yield all([fork(watchGetUser)]);
  yield all([fork(watchSaveUser)]);
  yield all([fork(watchSaveUserSuccess)]);
}
