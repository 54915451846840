import React, {Component} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import DocumentTable from "../components/DocumentTable";
import {actions as homeActions} from "../redux/home";
import {ApplicationState} from "../types";
import {IUser} from "../types/server";

interface StateProps {
  fetchingAssigned: boolean;
  assignedDocuments: any[];
  errorAssigned?: string;
  fetchingRecent: boolean;
  recentDocuments: any[];
  errorRecent?: string;
  authorizedUser: IUser;
}

interface DispatchProps {
  fetchAssignedDocuments: (user: IUser) => any;
  fetchRecentDocuments: () => any;
}

interface Props extends StateProps, DispatchProps {}

class Home extends Component<Props, {}> {

  public componentDidMount(): void {
    if (this.props.authorizedUser.canEdit) {
      this.props.fetchAssignedDocuments(this.props.authorizedUser);
    }
    this.props.fetchRecentDocuments();
  }

  public assignedDocuments = (userCanApprove: boolean, userCanEdit: boolean) => {
    if (this.props.authorizedUser.canEdit) {
      return (
        <div><span style={{marginLeft: "20px", top: "10px", fontSize: "20px", fontWeight: "bold"}}>ASSIGNED TO ME</span>
              <DocumentTable fetching={this.props.fetchingAssigned} documents={this.props.assignedDocuments} userCanApprove={userCanApprove} userCanEdit={userCanEdit}/>
        </div>);
    }
  }

  public render() {

    const {fetchingRecent, recentDocuments} = this.props;
    const userCanApprove: boolean = (this.props.authorizedUser.canApprove ? this.props.authorizedUser.canApprove : false);
    const userCanEdit: boolean = (this.props.authorizedUser.canEdit ? this.props.authorizedUser.canEdit : false);

    return (
      <div id="Home">
        {this.assignedDocuments(userCanApprove, userCanEdit)}
        <span style={{marginLeft: "20px", top: "10px", fontSize: "20px", fontWeight: "bold"}}>RECENT ACTIVITY</span>
        <DocumentTable fetching={fetchingRecent} documents={recentDocuments}  userCanApprove={userCanApprove} userCanEdit={userCanEdit}/>
      </div>
    );
  }

}

const mapStateToProps = (state: ApplicationState): StateProps => {

  const {home: homeState, auth: authState} = state;

  return {
    fetchingAssigned: homeState.fetchingAssigned,
    assignedDocuments: homeState.assignedDocuments,
    errorAssigned: homeState.errorAssigned,
    fetchingRecent: homeState.fetchingRecent,
    recentDocuments: homeState.recentDocuments,
    errorRecent: homeState.errorRecent,
    authorizedUser: authState.authorizedUser
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    fetchAssignedDocuments: (user: IUser) => dispatch(homeActions.getAssignedDocuments.request(user)),
    fetchRecentDocuments: () => dispatch(homeActions.getRecentDocuments.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
