import {Badge, Button, Checkbox, Col, Collapse, DatePicker, Form, InputNumber, Row} from "antd";
import Search from "antd/es/input/Search";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {RangePickerValue} from "antd/lib/date-picker/interface";
import moment from "moment";
import React, {FormEvent} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as appActions} from "../redux/app";
import {actions as documentActions} from "../redux/documents";
import {ApplicationState, AppUtilityState, SearchCriteriaState} from "../types";
import {ISearchCriteria} from "../types/server";
import SelectWithList from "./SelectWithList";

interface Props extends AppUtilityState, DispatchProps {}

class DocumentsSearchCriteria extends React.Component<Props, SearchCriteriaState> {

    constructor(props: any) {
      super(props);
      this.state = {searchCriteria: {excludeArchived: true}};
    }

  public componentDidMount(): void {
    this.props.fetchCategories();
    this.props.fetchDocumentStatusCounts();
    this.props.fetchDocumentTypeCounts();

    // JDJ  Go ahead and load the form documents for faster debugging/development. Comment out before commit
    // this.setState({searchCriteria: {...this.state.searchCriteria, documentType: 'Form'}}, () => {
    //   this.props.fetchDocuments(this.state.searchCriteria);
    // });
  }

  public searchDocuments = () => {
    console.log("searchCriteria ", this.state.searchCriteria);
    this.props.fetchDocuments(this.state.searchCriteria);
  }

  public handleClearAll =  (e: FormEvent) => {
    e.stopPropagation();
    this.setState({searchCriteria: {excludeArchived: true}}, () => {
      this.props.fetchDocuments(this.state.searchCriteria);
    });
  }

  public handleSearchTextChange = (e: FormEvent<HTMLInputElement>) => {
    this.setState({searchCriteria: {...this.state.searchCriteria, searchText: e.currentTarget.value}});
  }

  public handleSearchSubmit = (value: string) => {
    this.searchDocuments();
  }

  public onEffectiveDateChange = (dates: RangePickerValue, dateStrings: string[]) => {
    if (dates[0] && dates[1]) {
      this.setState({searchCriteria: {...this.state.searchCriteria, effectiveDateStart: dates[0].toDate(), effectiveDateEnd: dates[1].toDate()}}, () => {
        this.searchDocuments();
      });
    }
  }

  public onTermDateChange = (dates: RangePickerValue, dateStrings: string[]) => {
    if (dates[0] && dates[1]) {
      this.setState({searchCriteria: {...this.state.searchCriteria, termDateStart: dates[0].toDate(), termDateEnd: dates[1].toDate()}}, () => {
        this.searchDocuments();
      });
    }
  }

  public onReviewDateChange = (dates: RangePickerValue, dateStrings: string[]) => {
    if (dates[0] && dates[1]) {
      this.setState({searchCriteria: {...this.state.searchCriteria, reviewedDateStart: dates[0].toDate(), reviewedDateEnd: dates[1].toDate()}}, () => {
        this.searchDocuments();
      });
    }
  }

  public onReviewDueInDaysChange = (days: number | undefined) => {
    if (days) {
      this.setState({searchCriteria: {...this.state.searchCriteria, reviewDueInDays: days}}, () => {
        this.searchDocuments();
      });
    }
  }

  public handleTypeClick = (documentType: string) => (e: FormEvent) => {
    this.setState({searchCriteria: {...this.state.searchCriteria, documentType}}, () => {
      this.searchDocuments();
    });
  }

  public handleStatusClick = (status: string) => (e: FormEvent) => {
    this.setState({searchCriteria: {...this.state.searchCriteria, status}}, () => {
      this.searchDocuments();
    });
  }

  public onShowArchivedChange = (e: CheckboxChangeEvent) => {
    this.setState({searchCriteria: {...this.state.searchCriteria, excludeArchived: !e.target.checked}}, () => {
      this.searchDocuments();
    });
  }

  public handleTypeReset = (e: FormEvent) => {
    e.stopPropagation();
    this.setState({searchCriteria: {...this.state.searchCriteria, documentType: null}}, () => {
      this.searchDocuments();
    });
  }

  public typeResetElement = () => {
    if (this.state.searchCriteria.documentType && this.state.searchCriteria.documentType.length > 0) {
      return <Button size="small" onClick={this.handleTypeReset}>Reset</Button>;
    } else {
      return null;
    }
  }

  public handleStatusReset = (e: FormEvent) => {
    e.stopPropagation();
    this.setState({searchCriteria: {...this.state.searchCriteria, status: null}}, () => {
      this.searchDocuments();
    });
  }

  public statusResetElement = () => {
    if (this.state.searchCriteria.status && this.state.searchCriteria.status.length > 0) {
      return <Button size="small" onClick={this.handleStatusReset}>Reset</Button>;
    } else {
      return null;
    }
  }

  public onDepartmentChange = (value: string) => {
    this.setState({searchCriteria: {...this.state.searchCriteria, department: value}}, () => {
      this.searchDocuments();
    });
  }

  public onCategoryChange = (value: string) => {
    this.setState({searchCriteria: {...this.state.searchCriteria, category: value}}, () => {
      this.searchDocuments();
    });
  }

  public render() {

    const {departments, categories, documentTypeCounts, documentStatusCounts} = this.props;

    const { RangePicker } = DatePicker;

    const effectiveDateRangeDataProvider: any[] = [
      (this.state.searchCriteria.effectiveDateStart ? moment(this.state.searchCriteria.effectiveDateStart) : undefined),
      (this.state.searchCriteria.effectiveDateEnd ? moment(this.state.searchCriteria.effectiveDateEnd) : undefined)
    ];

    const termDateRangeDataProvider: any[] = [
      (this.state.searchCriteria.termDateStart ? moment(this.state.searchCriteria.termDateStart) : undefined),
      (this.state.searchCriteria.termDateEnd ? moment(this.state.searchCriteria.termDateEnd) : undefined)
    ];

    const reviewDateRangeDataProvider: any[] = [
      (this.state.searchCriteria.reviewedDateStart ? moment(this.state.searchCriteria.reviewedDateStart) : undefined),
      (this.state.searchCriteria.reviewedDateEnd ? moment(this.state.searchCriteria.reviewedDateEnd) : undefined)
    ];

    const reviewDueInDaysProvider: number = this.state.searchCriteria.reviewDueInDays ? this.state.searchCriteria.reviewDueInDays : undefined;

    const departmentProvider: string  = this.state.searchCriteria.department ? this.state.searchCriteria.department : undefined;

    const categoryProvider: string  = this.state.searchCriteria.category ? this.state.searchCriteria.category : undefined;

    const documentTypeList = documentTypeCounts.map((item) => (
      <div key={item.documentType}>
        <Row className={this.state.searchCriteria.documentType === item.documentType ? "row-selected" : ""}>
          <Col span={18}>
            <Button type="link" onClick={this.handleTypeClick(item.documentType)}>{item.documentType} </Button>
          </Col>
          <Col span={6} >
            <div style={{position: "absolute", right: "0px"}}>
              <Badge style={{ backgroundColor: "#fff", color: "#7e7e7e", boxShadow: "0 0 0 1px #d9d9d9 inset" }} count={item.count} overflowCount={1000} />
            </div>
          </Col>
        </Row>
      </div>
    ));

    // TODO: handle null status? now it will return all docs. Do we need to? Can/should we make the column not null?
    const documentStatusList = documentStatusCounts.map((item) => (
      <div key={item.status}>
        <Row type="flex" className={this.state.searchCriteria.status === item.status ? "row-selected" : ""}>
          <Col span={18} >
            <Button type="link" onClick={this.handleStatusClick(item.status)}>{(item.status == null ? "No Status" : item.status)} </Button>
          </Col>
          <Col span={6}>
            <div style={{position: "absolute", right: "0px"}}>
              <Badge style={{ backgroundColor: "#fff", color: "#999", boxShadow: "0 0 0 1px #d9d9d9 inset" }} count={item.count} overflowCount={1000}/>
            </div>
          </Col>
        </Row>
      </div>
    ));

    return (
      <Form id="SearchCriteria" layout="vertical">
        <div style={{margin: "10px"}}>
          <p style={{fontSize: "16px"}}>SEARCH</p>
          <Row type="flex" style={{marginTop: "5px", borderBottom: 0}}>
            <Col span={12}>
              <Button htmlType="button" onClick={this.handleClearAll}>Clear All</Button>
            </Col>
          </Row>
          <p style={{height: "10px", margin: "0px"}}/>
          <Search
            placeholder="title search"
            style={{ marginTop: "15px", width: 227 }}
            value={this.state.searchCriteria.searchText}
            onChange={this.handleSearchTextChange}
            onSearch={this.handleSearchSubmit}
          />

        </div>
        <Collapse defaultActiveKey={["1", "2", "3"]}>
          <Collapse.Panel header="Filters" key="1">
            <p>Effective Date</p>
            <RangePicker value={effectiveDateRangeDataProvider} allowClear={false} onChange={this.onEffectiveDateChange} />
            <p style={{marginTop: "10px"}}>Termination Date</p>
            <RangePicker value={termDateRangeDataProvider} allowClear={false} onChange={this.onTermDateChange} />
            <p style={{marginTop: "10px"}}>Review Date</p>
            <RangePicker value={reviewDateRangeDataProvider} allowClear={false} onChange={this.onReviewDateChange} />
            <div style={{margin: "10px"}}>Review due in <InputNumber value={reviewDueInDaysProvider} style={{width: "60px"}} onChange={this.onReviewDueInDaysChange}/> days</div>
            <p style={{height: "7px", margin: "0px"}}/>
            <SelectWithList items={departments} placeholder="Departments" value={departmentProvider} onChange={this.onDepartmentChange}/>
            <p style={{height: "7px", margin: "0px"}}/>
            <SelectWithList items={categories} placeholder="Categories"  value={categoryProvider} onChange={this.onCategoryChange}/>
            <Checkbox checked={!this.state.searchCriteria.excludeArchived} onChange={this.onShowArchivedChange}>Show Archived</Checkbox>
          </Collapse.Panel>
          <Collapse.Panel header="Document Type" key="2" extra={this.typeResetElement()}>
            {documentTypeList}
          </Collapse.Panel>
          <Collapse.Panel header="Document Status" key="3" extra={this.statusResetElement()}>
            {documentStatusList}
          </Collapse.Panel>
        </Collapse>
      </Form>
    );
  }
}

const mapStateToProps = (state: ApplicationState): AppUtilityState => {

  const {app: appUtilityState} = state;

  return {
    fetchingDepartments: appUtilityState.fetchingDepartments,
    departments: appUtilityState.departments,
    errorDepartments: appUtilityState.errorDepartments,
    fetchingCategories: appUtilityState.fetchingCategories,
    categories: appUtilityState.categories,
    errorCategories: appUtilityState.errorCategories,
    documentTypeCounts: appUtilityState.documentTypeCounts,
    documentStatusCounts: appUtilityState.documentStatusCounts,
    userDepartmentCounts: appUtilityState.userDepartmentCounts,
    userPermissionLevelCounts: appUtilityState.userPermissionLevelCounts
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchDocuments: (searchCriteria: ISearchCriteria) => dispatch(documentActions.getDocuments.request(searchCriteria)),
    fetchCategories: () => dispatch(appActions.getCategories.request()),
    fetchDocumentTypeCounts: () => dispatch(appActions.getDocumentTypeCounts.request()),
    fetchDocumentStatusCounts: () => dispatch(appActions.getDocumentStatusCounts.request())
  };
};

interface ConnectedSearchCriteriaDispatchProps {}

interface DispatchProps extends ConnectedSearchCriteriaDispatchProps {
    fetchDocuments: (searchCriteria: ISearchCriteria) => any;
    fetchCategories: () => any;
    fetchDocumentTypeCounts: () => any;
    fetchDocumentStatusCounts: () => any;
}

const ConnectedSearchCriteria: React.ComponentClass<ConnectedSearchCriteriaDispatchProps>  =
    connect<AppUtilityState, DispatchProps, ConnectedSearchCriteriaDispatchProps, ApplicationState>(mapStateToProps, mapDispatchToProps)(DocumentsSearchCriteria);

export default ConnectedSearchCriteria;
