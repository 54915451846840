import axios from "axios";
import {AnyAction} from "redux";
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {ISearchCriteria} from "../../../src/types/cwpolicy";
import {actions as appActions} from "../redux/app";
import {actions as documentActions, types as documentActionTypes} from "../redux/documents";
import {IDocument, IDocumentComment, IDocumentVersion} from "../types/server";

function fetchDocuments(searchCriteria: ISearchCriteria) {
  return axios({
    method: "post",
    url: "/api/documents",
    data: searchCriteria
  });
}

function viewDocument(filename: string, documentVersionId: number) {
  return axios({
    method: "get",
    url: "/api/documents/viewDocumentPdf",
    params: {filename, documentVersionId}
  });
}

function getDocumentById(documentId: number) {
  return axios({
    method: "get",
    url: "/api/documents/id",
      params: {
          documentId
      }
  });
}

function saveDocument(document: IDocument) {
  return axios({
    method: "post",
    url: "/api/documents/save",
    data: document
  });
}

function uploadDocument(fileUpload: {file: File, documentVersionId: number}) {
  const formData = new FormData();
  formData.append("file", fileUpload.file);
  formData.append("documentVersionId", fileUpload.documentVersionId.toString());

  return axios({
    method: "post",
    url: "/api/documents/upload",
    data: formData
  });
}

function updateDocumentCategories(documentCategories: {documentId: number, categories: string[]}) {
  return axios({
    method: "post",
    url: "/api/documents/updateDocumentCategories",
    data: documentCategories
  });
}

function updateDocumentDepartment(documentDepartment: {documentId: number, department: string}) {
  return axios({
    method: "post",
    url: "/api/documents/updateDocumentDepartment",
    data: documentDepartment
  });
}

function reviseDocument(document: IDocumentVersion) {
  return axios({
    method: "post",
    url: "/api/documents/revise",
    data: document
  });
}

function approveDocument(documentApproval: { documentId: number, documentVersionId: number, approvalDate: Date, approvalUserId: number, effectiveDate: Date }) {
  return axios({
    method: "post",
    url: "/api/documents/approve",
    data: documentApproval
  });
}

function updateDocumentAsReviewed(documentReview: { documentVersionId: number, reviewDate: Date, reviewUserId: number }) {
  return axios({
    method: "post",
    url: "/api/documents/updateDocumentAsReviewed",
    data: documentReview
  });
}

function updateTermDate(documentTerm: { documentId: number, documentVersionId: number, terminationDate: Date | null }) {
  return axios({
    method: "post",
    url: "/api/documents/updateTermDate",
    data: documentTerm
  });
}

function updateAutoRenew(documentAutoRenew: { documentId: number, autoRenew: boolean | null }) {
  return axios({
    method: "post",
    url: "/api/documents/updateAutoRenew",
    data: documentAutoRenew
  });
}

function addLinkedDocument(documentId: number, linkedDocumentId: number) {
  return axios({
    method: "get",
    url: "/api/documents/addLinkedDocument",
    params: {
      documentId,
      linkedDocumentId
    }
  });
}

function addComment(documentComment: IDocumentComment) {
  return axios({
    method: "post",
    url: "/api/documents/addComment",
    data: documentComment
  });
}

function removeLinkedDocument(documentId: number, linkedDocumentId: number) {
  return axios({
    method: "get",
    url: "/api/documents/removeLinkedDocument",
    params: {
      documentId,
      linkedDocumentId
    }
  });
}

function archiveDocument(documentArchival: { documentId: number, archivalDate: Date, archivalUserId: number }) {
  return axios({
    method: "post",
    url: "/api/documents/archive",
    data: documentArchival
  });
}

export function* watchFetchDocuments() {
  yield takeEvery(documentActionTypes.GET_DOCUMENTS_REQUEST, handleFetchDocuments);
}

export function* watchFetchAllDocuments() {
  yield takeEvery(documentActionTypes.GET_ALL_DOCUMENTS_REQUEST, handleFetchAllDocuments);
}

export function* watchGetDocumentById() {
  yield takeEvery(documentActionTypes.GET_DOCUMENT_BY_ID_REQUEST, handleGetDocumentById);
}

export function* watchSaveDocument() {
  yield takeEvery(documentActionTypes.SAVE_DOCUMENT_REQUEST, handleSaveDocument);
}

export function* watchUpdateDocumentCategories() {
  yield takeEvery(documentActionTypes.SAVE_DOCUMENT_CATEGORIES_REQUEST, handleUpdateDocumentCategories);
}

export function* watchUpdateDocumentDepartment() {
  yield takeEvery(documentActionTypes.SAVE_DOCUMENT_DEPARTMENT_REQUEST, handleUpdateDocumentDepartment);
}

export function* watchReviseDocument() {
  yield takeEvery(documentActionTypes.REVISE_DOCUMENT_REQUEST, handleReviseDocument);
}

export function* watchReviseDocumentSuccess() {
  yield takeEvery(documentActionTypes.REVISE_DOCUMENT_SUCCESS, handleReviseDocumentSuccess);
}

export function* watchSaveDocumentSuccess() {
  yield takeEvery(documentActionTypes.SAVE_DOCUMENT_SUCCESS, handleSaveDocumentSuccess);
}

export function* watchPostSaveDocumentSuccess() {
  yield takeEvery(documentActionTypes.GET_DOCUMENT_POST_SAVE_SUCCESS, handlePostSaveSuccess);
}

export function* watchApproveDocument() {
  yield takeEvery(documentActionTypes.APPROVE_DOCUMENT_REQUEST, handleApproveDocument);
}

export function* watchApproveDocumentSuccess() {
  yield takeEvery(documentActionTypes.APPROVE_DOCUMENT_SUCCESS, handleApproveDocumentSuccess);
}

export function* watchUpdateDocumentAsReviewed() {
  yield takeEvery(documentActionTypes.UPDATE_DOCUMENT_REVIEWED_REQUEST, handleUpdateDocumentAsReviewed);
}

export function* watchUpdateTermDate() {
  yield takeEvery(documentActionTypes.UPDATE_TERM_DATE_REQUEST, handleUpdateTermDate);
}

export function* watchUpdateAutoRenew() {
  yield takeEvery(documentActionTypes.UPDATE_AUTO_RENEW_REQUEST, handleUpdateAutoRenew);
}

export function* watchAddLinkedDocument() {
  yield takeEvery(documentActionTypes.ADD_LINKED_DOCUMENT_REQUEST, handleAddLinkedDocument);
}

export function* watchRemoveLinkedDocument() {
  yield takeEvery(documentActionTypes.REMOVE_LINKED_DOCUMENT_REQUEST, handleRemoveLinkedDocument);
}

export function* watchAddComment() {
  yield takeEvery(documentActionTypes.ADD_COMMENT_REQUEST, handleAddComment);
}

export function* watchUploadDocument() {
  yield takeEvery(documentActionTypes.UPLOAD_DOCUMENT_REQUEST, handleUploadDocument);
}

export function* watchArchiveDocument() {
  yield takeEvery(documentActionTypes.ARCHIVE_DOCUMENT_REQUEST, handleArchiveDocument);
}

export function* watchArchiveDocumentSuccess() {
  yield takeEvery(documentActionTypes.ARCHIVE_DOCUMENT_SUCCESS, handleArchiveDocumentSuccess);
}

export function* watchViewDocument() {
  yield takeEvery(documentActionTypes.VIEW_DOCUMENT_REQUEST, handleViewDocument);
}

function* handleViewDocument(action: AnyAction) {
  try {
    const response = yield call(viewDocument, action.payload.filename, action.payload.documentVersionId);

    if (response.error) {
      yield put(documentActions.viewDocument.failure(response.error));
    } else {
      yield put(documentActions.viewDocument.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.viewDocument.failure(error.stack!));
    } else {
      yield put(documentActions.viewDocument.failure("An unknown error occurred while viewing the document."));
    }
  }
}

function* handleFetchDocuments(action: AnyAction) {
  try {
    const response = yield call(fetchDocuments, action.payload.searchCriteria);

    if (response.error) {
      yield put(documentActions.getDocuments.failure(response.error));
    } else {
      yield put(documentActions.getDocuments.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.getDocuments.failure(error.stack!));
    } else {
      yield put(documentActions.getDocuments.failure("An unknown error occurred while fetching the documents."));
    }
  }
}

function* handleFetchAllDocuments(action: AnyAction) {
  try {
    const response = yield call(fetchDocuments, action.payload.searchCriteria);

    if (response.error) {
      yield put(documentActions.getAllDocuments.failure(response.error));
    } else {
      yield put(documentActions.getAllDocuments.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.getAllDocuments.failure(error.stack!));
    } else {
      yield put(documentActions.getAllDocuments.failure("An unknown error occurred while fetching the documents."));
    }
  }
}

function* handleGetDocumentById(action: AnyAction) {
  try {
    const response = yield call(getDocumentById, action.payload.documentId);

    if (response.error) {
      yield put(documentActions.getDocumentById.failure(response.error));
    } else {
      yield put(documentActions.getDocumentById.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.getDocumentById.failure(error.stack!));
    } else {
      yield put(documentActions.getDocumentById.failure("An unknown error occurred while getting the document."));
    }
  }
}

function* handleSaveDocument(action: AnyAction) {
  try {
    const response = yield call(saveDocument, action.payload.document);

    if (response.error) {
      yield put(documentActions.saveDocument.failure(response.error));
    } else {
      yield put(documentActions.saveDocument.success(response.data, {file: action.payload.file, documentVersionId: response.data.documentVersionId}, action.payload.lastSearch));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.saveDocument.failure(error.stack!));
    } else {
      yield put(documentActions.saveDocument.failure("An unknown error occurred while saving the document."));
    }
  }
}

function* handleSaveDocumentSuccess(action: AnyAction) {
  try {
    const response = yield call(getDocumentById, action.payload.document.id);
    yield put(documentActions.getDocuments.request(action.payload.lastSearch));
    yield put(appActions.getDocumentTypeCounts.request());
    yield put(appActions.getDocumentStatusCounts.request());

    if (response.error) {
      yield put(documentActions.getDocumentPostSave.failure(response.error));
    } else {
      yield put(documentActions.getDocumentPostSave.success(action.payload.fileUpload));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.getDocumentPostSave.failure(error.stack!));
    } else {
      yield put(documentActions.getDocumentPostSave.failure("An unknown error occurred while retrieving the document."));
    }
  }
}

function* handleUpdateDocumentCategories(action: AnyAction) {
  try {
    const response = yield call(updateDocumentCategories, action.payload.documentCategories);

    if (response.error) {
      yield put(documentActions.updateDocumentCategories.failure(response.error));
    } else {
      yield put(documentActions.updateDocumentCategories.success());
      yield put(documentActions.getDocumentById.request(action.payload.documentCategories.documentId));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.updateDocumentCategories.failure(error.stack!));
    } else {
      yield put(documentActions.updateDocumentCategories.failure("An unknown error occurred while saving the document categories."));
    }
  }
}

function* handleUpdateDocumentDepartment(action: AnyAction) {
  try {
    const response = yield call(updateDocumentDepartment, action.payload.documentDepartment);

    if (response.error) {
      yield put(documentActions.updateDocumentDepartment.failure(response.error));
    } else {
      yield put(documentActions.updateDocumentDepartment.success());
      yield put(documentActions.getDocumentById.request(action.payload.documentDepartment.documentId));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.updateDocumentDepartment.failure(error.stack!));
    } else {
      yield put(documentActions.updateDocumentDepartment.failure("An unknown error occurred while saving the document department."));
    }
  }
}

function* handleReviseDocument(action: AnyAction) {
  try {
    const response = yield call(reviseDocument, action.payload.document);

    if (response.error) {
      yield put(documentActions.reviseDocument.failure(response.error));
    } else {
      yield put(documentActions.reviseDocument.success({document: response.data, fileUpload: {file: action.payload.file, documentVersionId: response.data.documentVersionId}, lastSearch: action.payload.lastSearch}));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.reviseDocument.failure(error.stack!));
    } else {
      yield put(documentActions.reviseDocument.failure("An unknown error occurred while revising the document."));
    }
  }
}

function* handleReviseDocumentSuccess(action: AnyAction) {
  try {
    const response = yield call(getDocumentById, action.payload.document.id);
    yield put(documentActions.getDocuments.request(action.payload.lastSearch));
    yield put(appActions.getDocumentTypeCounts.request());
    yield put(appActions.getDocumentStatusCounts.request());

    if (response.error) {
      yield put(documentActions.getDocumentPostSave.failure(response.error));
    } else {
      yield put(documentActions.getDocumentPostSave.success(action.payload.fileUpload));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.getDocumentPostSave.failure(error.stack!));
    } else {
      yield put(documentActions.getDocumentPostSave.failure("An unknown error occurred while retrieving the document."));
    }
  }
}

function* handlePostSaveSuccess(action: AnyAction) {
  yield put(documentActions.uploadDocument.request(action.payload));
}

function* handleApproveDocument(action: AnyAction) {
  try {
    const response = yield call(approveDocument, action.payload.documentApproval);

    if (response.error) {
      yield put(documentActions.approveDocument.failure(response.error));
    } else {
      yield put(documentActions.approveDocument.success(action.payload.documentApproval.documentId, action.payload.lastSearch));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.approveDocument.failure(error.stack!));
    } else {
      yield put(documentActions.approveDocument.failure("An unknown error occurred while revising the document."));
    }
  }
}

function* handleApproveDocumentSuccess(action: AnyAction) {
  try {
    const response = yield call(getDocumentById, action.payload.documentId);
    yield put(documentActions.getDocuments.request(action.payload.lastSearch));
    yield put(appActions.getDocumentStatusCounts.request());

    if (response.error) {
      yield put(documentActions.getDocumentPostApprove.failure(response.error));
    } else {
      yield put(documentActions.getDocumentPostApprove.success());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.getDocumentPostApprove.failure(error.stack!));
    } else {
      yield put(documentActions.getDocumentPostApprove.failure("An unknown error occurred while retrieving the document."));
    }
  }
}

function* handleUpdateDocumentAsReviewed(action: AnyAction) {
  try {
    const response = yield call(updateDocumentAsReviewed, action.payload.documentReview);

    if (response.error) {
      yield put(documentActions.updateDocumentAsReviewed.failure(response.error));
    } else {
      yield put(documentActions.updateDocumentAsReviewed.success());
      yield put(documentActions.getDocumentById.request(action.payload.documentReview.documentId));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.updateDocumentAsReviewed.failure(error.stack!));
    } else {
      yield put(documentActions.updateDocumentAsReviewed.failure("An unknown error occurred while updating the document to reviewed."));
    }
  }
}

function* handleUpdateTermDate(action: AnyAction) {
  try {
    const response = yield call(updateTermDate, action.payload.documentTerm);

    if (response.error) {
      yield put(documentActions.updateTermDate.failure(response.error));
    } else {
      yield put(documentActions.updateTermDate.success());
      yield put(documentActions.getDocumentById.request(action.payload.documentTerm.documentId));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.updateTermDate.failure(error.stack!));
    } else {
      yield put(documentActions.updateTermDate.failure("An unknown error occurred while updating the document termination date."));
    }
  }
}

function* handleUpdateAutoRenew(action: AnyAction) {
  try {
    const response = yield call(updateAutoRenew, action.payload.documentAutoRenew);

    if (response.error) {
      yield put(documentActions.updateAutoRenew.failure(response.error));
    } else {
      yield put(documentActions.updateAutoRenew.success());
      yield put(documentActions.getDocumentById.request(action.payload.documentAutoRenew.documentId));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.updateAutoRenew.failure(error.stack!));
    } else {
      yield put(documentActions.updateAutoRenew.failure("An unknown error occurred while updating the document auto renewal."));
    }
  }
}

function* handleAddLinkedDocument(action: AnyAction) {
  try {
    const response = yield call(addLinkedDocument, action.payload.documentId, action.payload.linkedDocumentId);

    if (response.error) {
      yield put(documentActions.addLinkedDocument.failure(response.error));
    } else {
      yield put(documentActions.addLinkedDocument.success());
      yield put(documentActions.getDocumentById.request(action.payload.documentId));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.addLinkedDocument.failure(error.stack!));
    } else {
      yield put(documentActions.addLinkedDocument.failure("An unknown error occurred while saving the document link."));
    }
  }
}

function* handleRemoveLinkedDocument(action: AnyAction) {
  try {
    const response = yield call(removeLinkedDocument, action.payload.documentId, action.payload.linkedDocumentId);

    if (response.error) {
      yield put(documentActions.removeLinkedDocument.failure(response.error));
    } else {
      yield put(documentActions.removeLinkedDocument.success());
      yield put(documentActions.getDocumentById.request(action.payload.documentId));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.removeLinkedDocument.failure(error.stack!));
    } else {
      yield put(documentActions.removeLinkedDocument.failure("An unknown error occurred while removing the document link."));
    }
  }
}

function* handleAddComment(action: AnyAction) {
  try {
    const response = yield call(addComment, action.payload.documentComment);

    if (response.error) {
      yield put(documentActions.addComment.failure(response.error));
    } else {
      yield put(documentActions.addComment.success());
      yield put(documentActions.getDocumentById.request(action.payload.documentComment.documentId));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.addComment.failure(error.stack!));
    } else {
      yield put(documentActions.addComment.failure("An unknown error occurred while saving the document comment."));
    }
  }
}

function* handleUploadDocument(action: AnyAction) {
  try {
    const response = yield call(uploadDocument, {file: action.payload.file, documentVersionId: action.payload.documentVersionId});

    if (response.error) {
      yield put(documentActions.uploadDocument.failure(response.error));
    } else {
      yield put(documentActions.uploadDocument.success());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.uploadDocument.failure(error.stack!));
    } else {
      yield put(documentActions.uploadDocument.failure("An unknown error occurred while saving the document."));
    }
  }
}

function* handleArchiveDocument(action: AnyAction) {
  try {
    const response = yield call(archiveDocument, action.payload.documentArchival);

    if (response.error) {
      yield put(documentActions.archiveDocument.failure(response.error));
    } else {
      yield put(documentActions.archiveDocument.success(action.payload.documentArchival.documentId, action.payload.lastSearch));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.archiveDocument.failure(error.stack!));
    } else {
      yield put(documentActions.archiveDocument.failure("An unknown error occurred while archiving the document."));
    }
  }
}

function* handleArchiveDocumentSuccess(action: AnyAction) {
  try {
    const response = yield call(getDocumentById, action.payload.documentId);
    yield put(documentActions.getDocuments.request(action.payload.lastSearch));
    yield put(appActions.getDocumentStatusCounts.request());

    if (response.error) {
      yield put(documentActions.getDocumentPostApprove.failure(response.error));
    } else {
      yield put(documentActions.getDocumentPostApprove.success());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(documentActions.getDocumentPostApprove.failure(error.stack!));
    } else {
      yield put(documentActions.getDocumentPostApprove.failure("An unknown error occurred while retrieving the document."));
    }
  }
}

export function* documentSaga() {
  yield all([fork(watchFetchDocuments)]);
  yield all([fork(watchFetchAllDocuments)]);
  yield all([fork(watchGetDocumentById)]);
  yield all([fork(watchSaveDocument)]);
  yield all([fork(watchSaveDocumentSuccess)]);
  yield all([fork(watchPostSaveDocumentSuccess)]);
  yield all([fork(watchUpdateDocumentCategories)]);
  yield all([fork(watchUpdateDocumentDepartment)]);
  yield all([fork(watchReviseDocument)]);
  yield all([fork(watchReviseDocumentSuccess)]);
  yield all([fork(watchApproveDocument)]);
  yield all([fork(watchApproveDocumentSuccess)]);
  yield all([fork(watchUpdateDocumentAsReviewed)]);
  yield all([fork(watchUpdateTermDate)]);
  yield all([fork(watchUpdateAutoRenew)]);
  yield all([fork(watchAddLinkedDocument)]);
  yield all([fork(watchRemoveLinkedDocument)]);
  yield all([fork(watchAddComment)]);
  yield all([fork(watchUploadDocument)]);
  yield all([fork(watchArchiveDocument)]);
  yield all([fork(watchArchiveDocumentSuccess)]);
  yield all([fork(watchViewDocument)]);
}
