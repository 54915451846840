import {Layout} from "antd";
import React from "react";
import AppBar from "../components/AppBar";
import DocumentDetailSideBar from "../components/DocumentDetailSideBar";

const { Content } = Layout;

export default function withDefaultLayout(SideBar?: React.ComponentType<any>): <P extends object>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<P> {

  return <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class DefaultLayout extends React.Component<P> {
      public render() {

        return (
          <Layout>
            <AppBar/>
            <Layout>
              {SideBar != null &&
              <SideBar/>
              }
              <Layout style={{ width: "100%" }}>
                <Content>
                  <WrappedComponent {...this.props}/>
                </Content>
              </Layout>
              <DocumentDetailSideBar/>
            </Layout>
          </Layout>
        );
      }
    };
}
