import {Layout} from "antd";
import React from "react";

const {Content} = Layout;

const withEmptyLayout = (): <P extends object>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<P> => {

  return <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class DefaultLayout extends React.Component<P> {
      public render() {
        return (
          <Layout>
            <Content>
              <WrappedComponent {...this.props}/>
            </Content>
          </Layout>
        );
      }
    };
};

export default withEmptyLayout();
