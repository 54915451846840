import {Button, Form, Icon, Input} from "antd";
import {FormComponentProps} from "antd/lib/form";
import React, {Component, SyntheticEvent} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as authActions} from "../redux/auth";
import {ApplicationState, AuthState} from "../types";

interface DispatchProps {
  login: (username: string, password: string) => any;
}

interface Props extends AuthState, DispatchProps, FormComponentProps {}

class Login extends Component<Props, {}> {

  public state = {
    redirectToReferrer: false
  };

  public handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {username, password} = values;
        this.props.login(username, password);
      }
    });
  }

  public render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="Login">
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator("username", {
              rules: [{ required: true, message: "Please input your username!" }],
            })(
              <Input addonBefore={<Icon type="user" />} placeholder="Username"/>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input your Password!" }],
            })(
              <Input addonBefore={<Icon type="lock" />} type="password" placeholder="Password"/>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): AuthState => {

  const {auth: authState} = state;

  return {
    fetching: authState.fetching,
    authorizedUser: authState.authorizedUser,
    error: authState.error,
    roles: authState.roles || []
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    login: (username: string, password: string) => dispatch(authActions.login.request(username, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<Props>()(Login));
