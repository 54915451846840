import {Button, Icon, Modal, Upload} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {RcFile} from "antd/lib/upload";
import {UploadFile} from "antd/lib/upload/interface";
import React from "react";
import {Component, FormEvent} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as documentActions} from "../redux/documents";
import {ApplicationState} from "../types";
import {IDocument, IDocumentVersion, ISearchCriteria, IUser} from "../types/server";

interface ReviseDocumentProps {
  reviseDocumentModalVisible: boolean;
  setVisible: (visible: boolean) => any;
}

interface ReviseDocumentState {
  document: IDocument;
  authorizedUser: IUser;
  lastSearch: ISearchCriteria;
}

interface ReviseDocumentLocalState {
  commentToAdd?: string;
  file?: File;
  fileList?: UploadFile[];
}

interface ReviseDocumentDispatchProps {
  reviseDocument: (document: IDocumentVersion, file: File, lastSearch: ISearchCriteria) => any;
}

interface Props extends ReviseDocumentProps, ReviseDocumentDispatchProps, ReviseDocumentState {}

class ReviseDocumentModal extends Component<Props, ReviseDocumentLocalState> {

  constructor(props: any) {
    super(props);
    this.state = {
      commentToAdd: "",
    };
  }

  public handleFileSelectorChange = (file: RcFile, fileList: RcFile[]) => {
    this.setState({file, fileList});
    return false;
  }

  public handleCommentChange = (e: FormEvent<HTMLTextAreaElement>) => {
    this.setState({commentToAdd: e.currentTarget.value});
  }

  public handleOk = () => {
    if (this.state.file && this.props.document.id) {
      const documentVersion: IDocumentVersion = {...this.props.document,
        revisedUserId: this.props.authorizedUser.id,
        documentId: this.props.document.id,
        revisionComment: this.state.commentToAdd
      };
      this.props.reviseDocument(documentVersion, this.state.file, this.props.lastSearch);
      this.props.setVisible(false);
      this.handleAfterClose();
    }
  }

  public handleClose = () => {
    this.props.setVisible(false);
    this.handleAfterClose();
  }

  public handleAfterClose = () => {
    this.setState({commentToAdd: "", fileList: []});
  }

  public render() {
    return (
      <Modal title="Revise Document"
             width={450}
             visible={this.props.reviseDocumentModalVisible}
             onOk={this.handleOk}
             onCancel={this.handleClose}
             afterClose={this.handleAfterClose}
      >
        <p style={{marginTop: "10px", marginBottom: "5px"}}>File</p>
        <Upload multiple={false} fileList={this.state.fileList} accept=".doc,.docx,.pdf,.ppt,.xls,.xlsx" beforeUpload={this.handleFileSelectorChange} style={{width: "450px"}}>
          <Button style={{width: "100%"}}>
            <Icon type="upload"/> Choose File
          </Button>
        </Upload>
        <p style={{marginTop: "10px", marginBottom: "5px"}}>Comment</p>
        <TextArea
          autosize={{ minRows: 2, maxRows: 6 }}
          maxLength={2000}
          value={this.state.commentToAdd}
          onChange={this.handleCommentChange}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReviseDocumentState => {
  const {documents: documentState, auth: authState} = state;

  return {
    document: documentState.selectedDocument,
    authorizedUser: authState.authorizedUser,
    lastSearch: documentState.lastSearch
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReviseDocumentDispatchProps => {
  return {
    reviseDocument: (document: IDocumentVersion, file: File, lastSearch: ISearchCriteria, comment?: string) => dispatch(documentActions.reviseDocument.request(document, file, lastSearch))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviseDocumentModal);
