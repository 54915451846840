import {Layout} from "antd";
import React, {Component} from "react";
import SideBar from "./SideBar";
import UsersSearchCriteria from "./UsersSearchCriteria";

/*
interface SearchCriteriaProps {
    handleSearch: (searchCriteria: ISearchCriteria) => {}
}
*/

class UsersSearchSideBar extends Component {

  public render() {

//    const {handleSearch} = this.props;

    return (
      <Layout style={{flexShrink: 0}}>
        <SideBar/>
        <div>
          <section style={{minWidth: "150px", width: "250px", backgroundColor: "white", border: "1px solid gray", height: "100%"}}>
            <UsersSearchCriteria />
          </section>
        </div>
      </Layout>
    );
  }
}

export default UsersSearchSideBar;
