import {DatePicker, Modal} from "antd";
import {Moment} from "moment";
import moment from "moment";
import {Component} from "react";
import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as documentActions} from "../redux/documents";
import {ApplicationState} from "../types";
import {IDocument, ISearchCriteria, IUser} from "../types/server";

interface ApproveDocumentModalProps {
  document: IDocument | undefined;
  confirmApproveModalVisible: boolean;
  setVisible: (visible: boolean) => any;
}

interface ReviewDocumentState {
  authorizedUser: IUser;
  lastSearch: ISearchCriteria;
}

interface ApproveDocumentModalLocalState {
  effectiveDate?: Date;
}

interface DispatchProps {
  approveDocument: (documentApproval: { documentId: number, documentVersionId: number, approvalDate: Date, approvalUserId: number, effectiveDate: Date }, lastSearch: ISearchCriteria) => any;
}

interface Props extends ApproveDocumentModalProps, ReviewDocumentState, DispatchProps {}

class ApproveDocumentModal extends Component<Props, ApproveDocumentModalLocalState> {

  public handleOk = () => {
    const document = this.props.document;
    if (document && document.id && this.props.authorizedUser.id && this.state.effectiveDate) {
      this.props.approveDocument({documentId: document.id,
                                  documentVersionId: document.documentVersionId,
                                  approvalDate: new Date(),
                                  approvalUserId: this.props.authorizedUser.id,
                                  effectiveDate: this.state.effectiveDate}, this.props.lastSearch);
      this.props.setVisible(false);
    }
  }

  public handleClose = () => this.props.setVisible(false);

  public onEffectiveDateChange = (date: Moment, dateString: string) => {
    this.setState({effectiveDate: date.toDate()});
  }

  public disabledDate = (current: any): boolean => {
    return current && current.valueOf() <= moment().subtract(1, "days").toDate();
  }

  public render() {
    return (
      <Modal
        title="Approve Document?"
        visible={this.props.confirmApproveModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleClose}
      >
        <p>Please confirm that the document is approved and provide the effective date.</p>
        <DatePicker placeholder="Effective Date" disabledDate={this.disabledDate} onChange={this.onEffectiveDateChange}/>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReviewDocumentState => {

  const {auth: authState, documents: documentState} = state;

  return {
    authorizedUser: authState.authorizedUser,
    lastSearch: documentState.lastSearch
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    approveDocument: (documentApproval: { documentId: number, documentVersionId: number, approvalDate: Date, approvalUserId: number, effectiveDate: Date }, lastSearch: ISearchCriteria) => dispatch(documentActions.approveDocument.request(documentApproval, lastSearch))
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (ApproveDocumentModal);
