import React from "react";
import UsersSearchSideBar from "../components/UsersSearchSideBar";
import withDefaultLayout from "./DefaultLayout";

const withUsersLayout = (): <P extends object>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<P> => {

  return withDefaultLayout(UsersSearchSideBar);
};

export default withUsersLayout();
