import React from "react";
import DocumentSearchSideBar from "../components/DocumentSearchSideBar";
import withDefaultLayout from "./DefaultLayout";

const withDocumentsLayout = (): <P extends object>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<P> => {

  return withDefaultLayout(DocumentSearchSideBar);
};

export default withDocumentsLayout();
