import {Icon, Layout, Menu} from "antd";
import {push} from "connected-react-router";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {ApplicationState, IUser} from "../types";

const {Sider} = Layout;

interface StateProps {
  pathname: string;
  authorizedUser: IUser;
}

interface DispatchProps {
  push: (route: any) => any;
}

interface Props extends StateProps, DispatchProps {}

class SideBar extends Component<Props, {}> {

  public render() {

    const selectedMenuItem = this.props.pathname.substr(1);
    const userMenuOptionVisibility: any = this.props.authorizedUser.canEdit ? "default" : "hidden";

    return (
      <Sider width={70} theme="light">
        <Menu mode="vertical" style={{height: "50%", borderRight: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly"}}
              selectedKeys={[selectedMenuItem]}>
          <Menu.Item key="home">
            <Link to="/home">
              <Icon type="home" style={{fontSize: "28px"}}/>
            </Link>
          </Menu.Item>
          <Menu.Item key="documents">
            <Link to="/documents">
              <Icon type="file-search" style={{fontSize: "28px"}}/>
            </Link>
          </Menu.Item>
          <Menu.Item key="user" style={{visibility: userMenuOptionVisibility}}>
            <Link to="/user">
              <Icon type="team" style={{fontSize: "28px"}}/>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {

  const {router: routerState, auth: authState} = state;

  return {
    pathname: routerState.location.pathname,
    authorizedUser: authState.authorizedUser
  };
};

export default connect(mapStateToProps, { push })(SideBar);
