import {Col, Icon, Layout, Menu, Row} from "antd";
import { Button} from "antd/es/";
import React, { Component } from "react";
import {connect} from "react-redux";
import Popover from "react-tiny-popover";
import {Dispatch} from "redux";
import {actions as authActions} from "../redux/auth";
import {ApplicationState} from "../types";
import { IUser } from "../types/index";

const { Header } = Layout;

interface DispatchProps {
  logout: (userId: number) => any;
}

interface AppBarLocalState {
  authorizedUser: IUser;
  showPopover: boolean;
}

interface Props extends DispatchProps, AppBarLocalState {}

class AppBar extends Component<any, AppBarLocalState> {

  constructor(props: any) {
    super(props);
    this.state = {
      authorizedUser: null,
      showPopover: false
    };
  }

  public logout = (event: React.MouseEvent<any, MouseEvent>): void => {
    if (this.props.authorizedUser) {
      this.props.logout(this.props.authorizedUser.id);
    }
  }

  public onVisibleChange = (visible: boolean): void => {
    this.setState({ showPopover: visible });
  }

  public hidePopover = (): void => {
    this.setState({ showPopover: false });
  }

  public onClick = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({showPopover: !this.state.showPopover});
  }

  public render() {

    return (
      <Header style={{background: "#fff"}}>
        <Row type="flex" align={"bottom"} justify={"end"}>
           <Col span={6} pull={2}>
            <Menu id="AppBar" mode="horizontal" theme="light" style={{width: "100%", position: "relative", borderStyle: "none"}}>
                <Popover isOpen={this.state.showPopover} position={"bottom"} onClickOutside={this.hidePopover} content={(
                      <div style={{ backgroundColor: "black", opacity: 1, padding: "10px", paddingBottom: "50px", color: "white", borderRadius: "4px"}}>
                          <p>Name: { (this.props.authorizedUser) ? this.props.authorizedUser.firstname + "." + this.props.authorizedUser.lastname : ""}</p>
                          <p>Email: { (this.props.authorizedUser) ? this.props.authorizedUser.email : "" }</p>
                          <p>Permissions: { (this.props.authorizedUser) ? this.props.authorizedUser.permission : "" }</p>
                          <Button style={{opacity: 1, backgroundColor: "white", float: "right"}} onClick={this.logout}>Logout</Button>
                      </div>
                  )}>
                    <span style={{float: "right", paddingRight: "25px"}}>
                      <span style={{paddingRight: "10px"}}>{(this.props.authorizedUser) ? this.props.authorizedUser.firstname + " " + this.props.authorizedUser.lastname + " " : ""} ({this.props.authorizedUser.permission})</span>
                      <Icon type="user" style={{fontSize: "22px"}} onClick={this.onClick}/>
                    </span>
                </Popover>
            </Menu>
          </Col>
        </Row>
      </Header>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    logout: (userId: number) => dispatch(authActions.logout.request(userId))
  };
};

const mapStateToProps = (state: ApplicationState): any => {
  return {
    authorizedUser: state.auth.authorizedUser
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (AppBar);
