import {Modal} from "antd";
import {Component} from "react";
import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as documentActions} from "../redux/documents";
import {ApplicationState} from "../types";
import {IDocument, ISearchCriteria, IUser} from "../types/server";

interface ArchiveDocumentModalProps {
  document: IDocument | undefined;
  confirmArchiveModalVisible: boolean;
  setVisible: (visible: boolean) => any;
}

interface DispatchProps {
  archiveDocument: (documentArchival: {documentId: number, archivalDate: Date, archivalUserId: number}, lastSearch: ISearchCriteria) => any;
}

interface ArchiveDocumentState {
  authorizedUser: IUser;
  lastSearch: ISearchCriteria;
}

interface Props extends ArchiveDocumentModalProps, ArchiveDocumentState, DispatchProps {}

class ArchiveDocumentModal extends Component<Props, {}> {

  public handleOk = () => {
    const document = this.props.document;
    if (document && document.id && this.props.authorizedUser.id) {
      this.props.archiveDocument({documentId: document.id, archivalDate: new Date(), archivalUserId: this.props.authorizedUser.id}, this.props.lastSearch);
      this.props.setVisible(false);
    }
  }

  public handleClose = () => this.props.setVisible(false);

  public render() {
    return (
      <Modal
        title="Archive Document?"
        visible={this.props.confirmArchiveModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleClose}
      >
        <p>Please confirm that the document should be archived.</p>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ArchiveDocumentState => {

  const {auth: authState, documents: documentState} = state;

  return {
    authorizedUser: authState.authorizedUser,
    lastSearch: documentState.lastSearch
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    archiveDocument: (documentArchival: {documentId: number, archivalDate: Date, archivalUserId: number}, lastSearch: ISearchCriteria) => dispatch(documentActions.archiveDocument.request(documentArchival, lastSearch))
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (ArchiveDocumentModal);
