import axios from "axios";
import {AnyAction} from "redux";
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {actions as appActions, types as appActionTypes} from "../redux/app";

function fetchDepartments() {
  return axios({
    method: "get",
    url: "/api/departments"
  });
}

function fetchCategories() {
  return axios({
    method: "get",
    url: "/api/categories"
  });
}

function fetchDocumentTypeCounts() {
  return axios({
    method: "get",
    url: "/api/documents/countsByType"
  });
}

function fetchDocumentStatusCounts() {
  return axios({
    method: "get",
    url: "/api/documents/countsByStatus"
  });
}

function fetchUserDepartmentCounts() {
  return axios({
    method: "get",
    url: "/api/users/countsByDepartment"
  });
}

function fetchUserPermissionLevelCounts() {
  return axios({
    method: "get",
    url: "/api/users/countsByPermissionLevel"
  });
}

export function* watchFetchDepartments() {
  yield takeEvery(appActionTypes.GET_DEPARTMENTS_REQUEST, handleFetchDepartments);
}

export function* watchFetchCategories() {
  yield takeEvery(appActionTypes.GET_CATEGORIES_REQUEST, handleFetchCategories);
}

export function* watchFetchDocumentTypeCounts() {
  yield takeEvery(appActionTypes.GET_DOCUMENT_TYPE_COUNTS_REQUEST, handleFetchDocumentTypeCounts);
}

export function* watchFetchDocumentStatusCounts() {
  yield takeEvery(appActionTypes.GET_DOCUMENT_STATUS_COUNTS_REQUEST, handleFetchDocumentStatusCounts);
}

export function* watchFetchUserDepartmentCounts() {
  yield takeEvery(appActionTypes.GET_USER_DEPARTMENT_COUNTS_REQUEST, handleFetchUserDepartmentCounts);
}

export function* watchFetchUserPermissionLevelCounts() {
  yield takeEvery(appActionTypes.GET_USER_PERMISSION_LEVEL_COUNTS_REQUEST, handleFetchUserPermissionLevelCounts);
}

function* handleFetchDepartments(action: AnyAction) {
  try {
    const response = yield call(fetchDepartments);

    if (response.error) {
      yield put(appActions.getDepartments.failure(response.error));
    } else {
      yield put(appActions.getDepartments.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(appActions.getDepartments.failure(error.stack!));
    } else {
      yield put(appActions.getDepartments.failure("An unknown error occurred while fetching the departments."));
    }
  }
}

function* handleFetchCategories(action: AnyAction) {
  try {
    const response = yield call(fetchCategories);

    if (response.error) {
      yield put(appActions.getCategories.failure(response.error));
    } else {
      yield put(appActions.getCategories.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(appActions.getCategories.failure(error.stack!));
    } else {
      yield put(appActions.getCategories.failure("An unknown error occurred while fetching the document categories."));
    }
  }
}

function* handleFetchDocumentTypeCounts(action: AnyAction) {
  try {
    const response = yield call(fetchDocumentTypeCounts);

    if (response.error) {
      yield put(appActions.getDocumentTypeCounts.failure(response.error));
    } else {
      yield put(appActions.getDocumentTypeCounts.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(appActions.getDocumentTypeCounts.failure(error.stack!));
    } else {
      yield put(appActions.getDocumentTypeCounts.failure("An unknown error occurred while fetching the document type counts."));
    }
  }
}

function* handleFetchDocumentStatusCounts(action: AnyAction) {
  try {
    const response = yield call(fetchDocumentStatusCounts);

    if (response.error) {
      yield put(appActions.getDocumentStatusCounts.failure(response.error));
    } else {
      yield put(appActions.getDocumentStatusCounts.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(appActions.getDocumentStatusCounts.failure(error.stack!));
    } else {
      yield put(appActions.getDocumentStatusCounts.failure("An unknown error occurred while fetching the document status counts."));
    }
  }
}

function* handleFetchUserDepartmentCounts(action: AnyAction) {
  try {
    const response = yield call(fetchUserDepartmentCounts);

    if (response.error) {
      yield put(appActions.getUserDepartmentCounts.failure(response.error));
    } else {
      yield put(appActions.getUserDepartmentCounts.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(appActions.getUserDepartmentCounts.failure(error.stack!));
    } else {
      yield put(appActions.getUserDepartmentCounts.failure("An unknown error occurred while fetching the user department counts."));
    }
  }
}

function* handleFetchUserPermissionLevelCounts(action: AnyAction) {
  try {
    const response = yield call(fetchUserPermissionLevelCounts);

    if (response.error) {
      yield put(appActions.getUserPermissionLevelCounts.failure(response.error));
    } else {
      yield put(appActions.getUserPermissionLevelCounts.success(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(appActions.getUserPermissionLevelCounts.failure(error.stack!));
    } else {
      yield put(appActions.getUserPermissionLevelCounts.failure("An unknown error occurred while fetching the user permission level counts."));
    }
  }
}

export function* appSaga() {
  yield all([fork(watchFetchDepartments)]);
  yield all([fork(watchFetchCategories)]);
  yield all([fork(watchFetchDocumentTypeCounts)]);
  yield all([fork(watchFetchDocumentStatusCounts)]);
  yield all([fork(watchFetchUserDepartmentCounts)]);
  yield all([fork(watchFetchUserPermissionLevelCounts)]);
}
