import {Button, Form, Icon, Input, InputNumber, List, Modal, Select, Upload} from "antd";
import {FormComponentProps} from "antd/lib/form";
import {RcFile} from "antd/lib/upload";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as documentActions} from "../redux/documents";
import {AddDocumentLocalState, AddDocumentState, ApplicationState} from "../types";
import {IBaseDocument, ICategory, IDocument, ISearchCriteria} from "../types/server";
import SelectWithList from "./SelectWithList";

interface Props extends DispatchProps, FormComponentProps, ConnectedAddDocumentModalDispatchProps, AddDocumentState {}

class AddDocumentModal extends Component<Props, AddDocumentLocalState> {

  constructor(props: any) {
    super(props);
    this.state = {
      selectedCategories: [],
      selectedLinkedDocuments: []
    };
  }

  public componentDidMount() {
    if (this.props.documents === null || this.props.documents.length === 0) {
      this.props.fetchAllDocuments({});
    }
  }

  public handleOk = () => {
    this.props.form.setFieldsValue({
      categories: this.state.selectedCategories.map(({name}) => name),
      linkedDocuments: this.state.selectedLinkedDocuments
    });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const document: IDocument = values;
        document.revisedDate = new Date();
        document.revisedUserId = this.props.authorizedUser.id;
        if (this.state.file) {
          this.props.saveDocument(values, this.state.file, this.props.lastSearch);
        }
        this.props.setVisible(false);
        this.handleAfterClose();
      }
    });
  }

  public handleClose = () => {
    this.props.setVisible(false);
    this.handleAfterClose();
  }

  public handleAfterClose = () => {
    this.setState({selectedCategories: [],
                         selectedLinkedDocuments: [],
                         categoryToAdd: null,
                         linkedDocumentToAdd: null,
                         fileList: []});
  }

  public onCategoryChange = (selectedCategoryId: number) => {
    const categoryToAdd: ICategory = this.props.categories.find((category) => category.id === selectedCategoryId);
    this.setState({categoryToAdd});
  }

  public removeCategory = (selectedCategoryId: number | undefined) => {
    if (selectedCategoryId) {
      const categoryToRemove: ICategory = this.props.categories.find((category) => category.id === selectedCategoryId);
      const newSelectedCategories: ICategory[] = this.state.selectedCategories;
      const indexToRemove = newSelectedCategories.indexOf(categoryToRemove);
      if (indexToRemove !== -1) {
        newSelectedCategories.splice(indexToRemove, 1);
        this.setState({selectedCategories: newSelectedCategories});
      }
    }
  }

  public addCategory = () => {
    if (this.state.categoryToAdd) {
      this.setState({
        selectedCategories: [...this.state.selectedCategories, this.state.categoryToAdd],
        categoryToAdd: null
      });
    }
  }

  public onLinkedDocumentChange = (selectedLinkedDocumentId: number) => {
    const linkedDocumentToAdd: IBaseDocument = this.props.documents.find((document) => document.id === selectedLinkedDocumentId);
    this.setState({linkedDocumentToAdd});
  }

  public handleRemoveLinkedDocument = (selectedLinkedDocumentId: number | undefined) => {
    if (selectedLinkedDocumentId) {
      const linkedDocumentToRemove: IBaseDocument = this.props.documents.find((document) => document.id === selectedLinkedDocumentId);
      const newSelectedLinkedDocuments: IBaseDocument[] = this.state.selectedLinkedDocuments;
      const indexToRemove = newSelectedLinkedDocuments.indexOf(linkedDocumentToRemove);
      if (indexToRemove !== -1) {
        newSelectedLinkedDocuments.splice(indexToRemove, 1);
        this.setState({selectedLinkedDocuments: newSelectedLinkedDocuments});
      }
    }
  }

  public addLinkedDocument = () => {
    if (this.state.linkedDocumentToAdd) {
      this.setState({
        selectedLinkedDocuments: [...this.state.selectedLinkedDocuments, this.state.linkedDocumentToAdd],
        linkedDocumentToAdd: null
      });
    }
  }

  public normFile = (e: any) => {
    if (Array.isArray(e.fileList) && e.fileList.length > 1) {
      e.fileList = e.fileList.slice(e.fileList.length - 1);
    }
    return e;
  }

  public handleFileSelectorChange = (file: RcFile, fileList: RcFile[]) => {
    this.setState({file, fileList});
    return false;
  }

  public render() {
    const {getFieldDecorator} = this.props.form;
    const {selectedLinkedDocuments, selectedCategories} = this.state;
    const filteredLinkedDocuments: IBaseDocument[] = this.props.documents.filter((doc) => !selectedLinkedDocuments.includes(doc));
    const filteredCategories: ICategory[] = this.props.categories.filter((category) => !selectedCategories.includes(category));

    const documentTypes: string[] = ["Policy", "Procedure", "Contract", "Form", "Other", "Other (Attachment)"];

    const documentTypeSelectList = documentTypes.map((item) => (
      <Select.Option key={item} value={item}>
        {item}
      </Select.Option>
    ));

    getFieldDecorator("categories", {initialValue: []});
    const categoryList = (selectedCategories != null && selectedCategories.length > 0 ?
      <List dataSource={selectedCategories}
            renderItem={(category: ICategory) =>
              <List.Item actions={[<Button icon={"close-circle"} size="small"
                                           onClick={() => this.removeCategory(category.id)}/>]}>
                {category.name}</List.Item>}>
      </List>
      : "");

    getFieldDecorator("linkedDocuments", {initialValue: []});
    const linkedDocumentsList = (selectedLinkedDocuments != null && selectedLinkedDocuments.length > 0 ?
      <List dataSource={selectedLinkedDocuments}
            renderItem={(linkedDocument: IBaseDocument) =>
              <List.Item actions={[<Button icon={"close-circle"} size="small"
                                           onClick={() => this.handleRemoveLinkedDocument(linkedDocument.id)}/>]}>
                {linkedDocument.title}</List.Item>}>
      </List>
      : "");

    const categoriesLabel = <span><label>Categories</label><span
      style={{fontSize: "12px", fontWeight: "lighter"}}> (optional)</span></span>;

    return (
      <Modal title="Add New Document"
             width={850}
             visible={this.props.addDocumentModalVisible}
             destroyOnClose={true}
             onOk={this.handleOk}
             onCancel={this.handleClose}
             afterClose={this.handleAfterClose}
      >
        <Form id="Form" layout="vertical" style={{paddingBottom: "0px"}}>
          <div style={{display: "flex"}}>
            <div style={{width: "500px", paddingRight: "20px"}}>
              <Form.Item label="Document Name" className="login-form-item">
                {getFieldDecorator("title", {
                  rules: [{required: true, message: "Please provide document name"}],
                })(
                  <Input placeholder="Document Name"/>
                )}
              </Form.Item>
              <Form.Item label="Responsible Department">
                {getFieldDecorator("responsibleDepartment", {
                  rules: [{required: true, message: "Please provide the responsible department"}],
                })(
                  <SelectWithList items={this.props.departments} placeholder="Responsible Department"/>
                )}
              </Form.Item>
              <Form.Item label="Attachment">
                {
                  getFieldDecorator("upload", {
                    rules: [{required: true, message: "Please provide an attachment"}],
                    getValueFromEvent: this.normFile
                  })(<Upload multiple={false} fileList={this.state.fileList} accept=".doc,.docx,.pdf,.ppt,.xls,.xlsx"
                             beforeUpload={this.handleFileSelectorChange} style={{width: "100%"}}>
                    <Button style={{width: "100%"}}>
                      <Icon type="upload"/> Choose File
                    </Button>
                  </Upload>)
                }
              </Form.Item>
              <Form.Item label="Linked Documents">
                {linkedDocumentsList}
                <Select value={(this.state.linkedDocumentToAdd ? this.state.linkedDocumentToAdd.id : null)}
                        showSearch
                        placeholder="Linked Document"
                        onChange={this.onLinkedDocumentChange}
                        optionFilterProp="title"
                        filterOption={(input, option) => {
                          if (option && option.props && option.props.children) {
                            return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          } else {
                            return false;
                          }
                        }
                        }
                        allowClear={true}
                >
                  {filteredLinkedDocuments.map((linkedDocument) => (
                    <Select.Option key={linkedDocument.id} value={linkedDocument.id}>
                      {linkedDocument.title}
                    </Select.Option>
                  ))}
                </Select>
                <Button disabled={!this.state.linkedDocumentToAdd} onClick={this.addLinkedDocument}
                        style={{position: "absolute", right: "0px", top: "30px"}}>
                  <Icon type="plus"/> Add linked document
                </Button>
              </Form.Item>
            </div>
            <div style={{width: "300px"}}>
              <Form.Item label="Type">
                {getFieldDecorator("documentType", {
                  rules: [{required: true, message: "Please provide document type"}],
                })(
                  <Select allowClear={false} placeholder="Document Type">
                    {documentTypeSelectList}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Review Cycle Years" style={{marginTop: "10px"}}>
                {getFieldDecorator("reviewCycleYears", {
                  rules: [{required: true, message: "Please specify the review cycle"}],
                  initialValue: 1
                })(
                  <InputNumber style={{width: "75px"}} min={1} max={10}/>
                )}
              </Form.Item>

              <Form.Item label={categoriesLabel}>
                {categoryList}
                <Select value={(this.state.categoryToAdd ? this.state.categoryToAdd.id : null)}
                        showSearch
                        placeholder="Category"
                        onChange={this.onCategoryChange}
                        optionFilterProp="name"
                        filterOption={(input, option) => {
                          if (option && option.props && option.props.children) {
                            return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          } else {
                            return false;
                          }
                        }
                        }
                        allowClear={true}
                >
                  {filteredCategories.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
                <Button disabled={!this.state.categoryToAdd} onClick={this.addCategory}
                        style={{position: "absolute", right: "0px", top: "30px"}}>
                  <Icon type="plus"/> Add Category
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState): AddDocumentState => {
  const {app: appUtilityState, documents: documentState, auth: authState} = state;

  return {
    authorizedUser: authState.authorizedUser,
    categories: appUtilityState.categories,
    departments: appUtilityState.departments,
    documents: documentState.allDocuments,
    lastSearch: documentState.lastSearch
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchAllDocuments: (searchCriteria: ISearchCriteria) => dispatch(documentActions.getAllDocuments.request(searchCriteria)),
    saveDocument: (document: IDocument, file: File, lastSearch: ISearchCriteria) => dispatch(documentActions.saveDocument.request(document, file, lastSearch))
  };
};

interface ConnectedAddDocumentModalDispatchProps {
  addDocumentModalVisible: boolean;
  setVisible: (visible: boolean) => any;
}

interface DispatchProps {
  fetchAllDocuments: (searchCriteria: ISearchCriteria) => any;
  saveDocument: (document: IDocument, file: File, lastSearch: ISearchCriteria) => any;
}

const ConnectedAddDocumentModal: React.ComponentClass<ConnectedAddDocumentModalDispatchProps> =
  connect<AddDocumentState, DispatchProps, ConnectedAddDocumentModalDispatchProps, ApplicationState>(mapStateToProps, mapDispatchToProps)(Form.create()(AddDocumentModal));

export default ConnectedAddDocumentModal;
