import {AnyAction, Reducer} from "redux";
import {action} from "typesafe-actions";
import {ISearchCriteria} from "../../../src/types/cwpolicy";
import {DocumentState, IDocument} from "../types";
import {IDocumentComment, IDocumentVersion} from "../types/server";

export const types = {
  GET_DOCUMENTS_REQUEST: "DOCUMENTS/GET_DOCUMENTS_REQUEST",
  GET_DOCUMENTS_SUCCESS: "DOCUMENTS/GET_DOCUMENTS_SUCCESS",
  GET_DOCUMENTS_FAILURE: "DOCUMENTS/GET_DOCUMENTS_FAILURE",
  GET_ALL_DOCUMENTS_REQUEST: "DOCUMENTS/GET_ALL_DOCUMENTS_REQUEST",
  GET_ALL_DOCUMENTS_SUCCESS: "DOCUMENTS/GET_ALL_DOCUMENTS_SUCCESS",
  GET_ALL_DOCUMENTS_FAILURE: "DOCUMENTS/GET_ALL_DOCUMENTS_FAILURE",
  GET_DOCUMENT_BY_ID_REQUEST: "DOCUMENTS/GET_DOCUMENT_BY_ID_REQUEST",
  GET_DOCUMENT_BY_ID_SUCCESS: "DOCUMENTS/GET_DOCUMENT_BY_ID_SUCCESS",
  GET_DOCUMENT_BY_ID_FAILURE: "DOCUMENTS/GET_DOCUMENT_BY_ID_FAILURE",
  GET_DOCUMENT_POST_SAVE_REQUEST: "DOCUMENTS/GET_DOCUMENT_POST_SAVE_REQUEST",
  GET_DOCUMENT_POST_SAVE_SUCCESS: "DOCUMENTS/GET_DOCUMENT_POST_SAVE_SUCCESS",
  GET_DOCUMENT_POST_SAVE_FAILURE: "DOCUMENTS/GET_DOCUMENT_POST_SAVE_FAILURE",
  GET_DOCUMENT_POST_APPROVE_SUCCESS: "DOCUMENTS/GET_DOCUMENT_POST_APPROVE_SUCCESS",
  GET_DOCUMENT_POST_APPROVE_FAILURE: "DOCUMENTS/GET_DOCUMENT_POST_APPROVE_FAILURE",
  SAVE_DOCUMENT_REQUEST: "DOCUMENTS/SAVE_DOCUMENT_REQUEST",
  SAVE_DOCUMENT_SUCCESS: "DOCUMENTS/SAVE_DOCUMENT_SUCCESS",
  SAVE_DOCUMENT_FAILURE: "DOCUMENTS/SAVE_DOCUMENT_FAILURE",
  SAVE_DOCUMENT_CATEGORIES_REQUEST: "DOCUMENTS/SAVE_DOCUMENT_CATEGORIES_REQUEST",
  SAVE_DOCUMENT_CATEGORIES_SUCCESS: "DOCUMENTS/SAVE_DOCUMENT_CATEGORIES_SUCCESS",
  SAVE_DOCUMENT_CATEGORIES_FAILURE: "DOCUMENTS/SAVE_DOCUMENT_CATEGORIES_FAILURE",
  SAVE_DOCUMENT_DEPARTMENT_REQUEST: "DOCUMENTS/SAVE_DOCUMENT_DEPARTMENT_REQUEST",
  SAVE_DOCUMENT_DEPARTMENT_SUCCESS: "DOCUMENTS/SAVE_DOCUMENT_DEPARTMENT_SUCCESS",
  SAVE_DOCUMENT_DEPARTMENT_FAILURE: "DOCUMENTS/SAVE_DOCUMENT_DEPARTMENT_FAILURE",
  REVISE_DOCUMENT_REQUEST: "DOCUMENTS/REVISE_DOCUMENT_REQUEST",
  REVISE_DOCUMENT_SUCCESS: "DOCUMENTS/REVISE_DOCUMENT_SUCCESS",
  REVISE_DOCUMENT_FAILURE: "DOCUMENTS/REVISE_DOCUMENT_FAILURE",
  APPROVE_DOCUMENT_REQUEST: "DOCUMENTS/APPROVE_DOCUMENT_REQUEST",
  APPROVE_DOCUMENT_SUCCESS: "DOCUMENTS/APPROVE_DOCUMENT_SUCCESS",
  APPROVE_DOCUMENT_FAILURE: "DOCUMENTS/APPROVE_DOCUMENT_FAILURE",
  UPDATE_DOCUMENT_REVIEWED_REQUEST: "DOCUMENTS/UPDATE_DOCUMENT_REVIEWED_REQUEST",
  UPDATE_DOCUMENT_REVIEWED_SUCCESS: "DOCUMENTS/UPDATE_DOCUMENT_REVIEWED_SUCCESS",
  UPDATE_DOCUMENT_REVIEWED_FAILURE: "DOCUMENTS/UPDATE_DOCUMENT_REVIEWED_FAILURE",
  UPDATE_TERM_DATE_REQUEST: "DOCUMENTS/UPDATE_TERM_DATE_REQUEST",
  UPDATE_TERM_DATE_SUCCESS: "DOCUMENTS/UPDATE_TERM_DATE_SUCCESS",
  UPDATE_TERM_DATE_FAILURE: "DOCUMENTS/UPDATE_TERM_DATE_FAILURE",
  UPDATE_AUTO_RENEW_REQUEST: "DOCUMENTS/UPDATE_AUTO_RENEW_REQUEST",
  UPDATE_AUTO_RENEW_SUCCESS: "DOCUMENTS/UPDATE_AUTO_RENEW_SUCCESS",
  UPDATE_AUTO_RENEW_FAILURE: "DOCUMENTS/UPDATE_AUTO_RENEW_FAILURE",
  ADD_LINKED_DOCUMENT_REQUEST: "DOCUMENTS/ADD_LINKED_DOCUMENT_REQUEST",
  ADD_LINKED_DOCUMENT_SUCCESS: "DOCUMENTS/ADD_LINKED_DOCUMENT_SUCCESS",
  ADD_LINKED_DOCUMENT_FAILURE: "DOCUMENTS/ADD_LINKED_DOCUMENT_FAILURE",
  REMOVE_LINKED_DOCUMENT_REQUEST: "DOCUMENTS/REMOVE_LINKED_DOCUMENT_REQUEST",
  REMOVE_LINKED_DOCUMENT_SUCCESS: "DOCUMENTS/REMOVE_LINKED_DOCUMENT_SUCCESS",
  REMOVE_LINKED_DOCUMENT_FAILURE: "DOCUMENTS/REMOVE_LINKED_DOCUMENT_FAILURE",
  ADD_COMMENT_REQUEST: "DOCUMENTS/ADD_COMMENT_REQUEST",
  ADD_COMMENT_SUCCESS: "DOCUMENTS/ADD_COMMENT_SUCCESS",
  ADD_COMMENT_FAILURE: "DOCUMENTS/ADD_COMMENT_FAILURE",
  UPLOAD_DOCUMENT_REQUEST: "DOCUMENTS/UPLOAD_DOCUMENT_REQUEST",
  UPLOAD_DOCUMENT_SUCCESS: "DOCUMENTS/UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_FAILURE: "DOCUMENTS/UPLOAD_DOCUMENT_FAILURE",
  ARCHIVE_DOCUMENT_REQUEST: "DOCUMENTS/ARCHIVE_DOCUMENT_REQUEST",
  ARCHIVE_DOCUMENT_SUCCESS: "DOCUMENTS/ARCHIVE_DOCUMENT_SUCCESS",
  ARCHIVE_DOCUMENT_FAILURE: "DOCUMENTS/ARCHIVE_DOCUMENT_FAILURE",
  VIEW_DOCUMENT_REQUEST: "DOCUMENTS/VIEW_DOCUMENT_REQUEST",
  VIEW_DOCUMENT_SUCCESS: "DOCUMENTS/VIEW_DOCUMENT_SUCCESS",
  VIEW_DOCUMENT_FAILURE: "DOCUMENTS/VIEW_DOCUMENT_FAILURE",
  SELECT_DOCUMENT: "DOCUMENTS/SELECT_DOCUMENT"
};

export const initialState: DocumentState = {
  fetching: false,
  documents: [],
  error: "",
  fetchingAll: false,
  allDocuments: [],
  errorAll: "",
  addDocumentModalVisible: false,
  authorizedUser: null
};

export const reducer: Reducer<DocumentState> = (state: DocumentState = initialState, action: AnyAction) => {

  switch (action.type) {
    case types.GET_DOCUMENTS_REQUEST:
      return {...state, fetching: true, error: "", lastSearch: action.payload.searchCriteria};
    case types.GET_DOCUMENTS_SUCCESS:
      return {...state, fetching: false, documents: action.payload.data};
    case types.GET_DOCUMENTS_FAILURE:
      return {...state, fetching: false, documents: [], error: action.payload.data};
    case types.GET_ALL_DOCUMENTS_REQUEST:
      return {...state, fetchingAll: true, errorAll: ""};
    case types.GET_ALL_DOCUMENTS_SUCCESS:
      return {...state, fetchingAll: false, allDocuments: action.payload.data};
    case types.GET_ALL_DOCUMENTS_FAILURE:
      return {...state, fetchingAll: false, allDocuments: [], errorAll: action.payload.data};
    case types.GET_DOCUMENT_BY_ID_REQUEST:
      return {...state};
    case types.GET_DOCUMENT_BY_ID_SUCCESS:
      return {...state, fetching: false, selectedDocument: action.payload.data};
    case types.GET_DOCUMENT_BY_ID_FAILURE:
      return {...state, fetching: false, selectedDocument: null, error: action.payload.data};
    case types.GET_DOCUMENT_POST_SAVE_REQUEST:
      return {...state};
    case types.GET_DOCUMENT_POST_SAVE_SUCCESS:
      return {...state, fetching: false, selectedDocument: action.payload.data};
    case types.GET_DOCUMENT_POST_SAVE_FAILURE:
      return {...state, fetching: false, selectedDocument: null, error: action.payload.data};
    case types.GET_DOCUMENT_POST_APPROVE_SUCCESS:
      return {...state, fetching: false};
    case types.GET_DOCUMENT_POST_APPROVE_FAILURE:
      return {...state, fetching: false, selectedDocument: null, error: action.payload.data};
    case types.SAVE_DOCUMENT_REQUEST:
      return {...state};
    case types.SAVE_DOCUMENT_SUCCESS:
      return {...state};
    case types.SAVE_DOCUMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.SAVE_DOCUMENT_CATEGORIES_REQUEST:
      return {...state};
    case types.SAVE_DOCUMENT_CATEGORIES_SUCCESS:
      return {...state};
    case types.SAVE_DOCUMENT_CATEGORIES_FAILURE:
      return {...state, error: action.payload.data};
    case types.SAVE_DOCUMENT_DEPARTMENT_REQUEST:
      return {...state};
    case types.SAVE_DOCUMENT_DEPARTMENT_SUCCESS:
      return {...state};
    case types.SAVE_DOCUMENT_DEPARTMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.REVISE_DOCUMENT_REQUEST:
      return {...state};
    case types.REVISE_DOCUMENT_SUCCESS:
      return {...state, selectedDocument: action.payload.data};
    case types.REVISE_DOCUMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.APPROVE_DOCUMENT_REQUEST:
      return {...state};
    case types.APPROVE_DOCUMENT_SUCCESS:
      return {...state, selectedDocument: action.payload.data};
    case types.APPROVE_DOCUMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.UPDATE_DOCUMENT_REVIEWED_REQUEST:
      return {...state};
    case types.UPDATE_DOCUMENT_REVIEWED_SUCCESS:
      return {...state};
    case types.UPDATE_DOCUMENT_REVIEWED_FAILURE:
      return {...state, error: action.payload.data};
    case types.UPDATE_TERM_DATE_REQUEST:
      return {...state};
    case types.UPDATE_TERM_DATE_SUCCESS:
      return {...state};
    case types.UPDATE_TERM_DATE_FAILURE:
      return {...state, error: action.payload.data};
    case types.UPDATE_AUTO_RENEW_REQUEST:
      return {...state};
    case types.UPDATE_AUTO_RENEW_SUCCESS:
      return {...state};
    case types.UPDATE_AUTO_RENEW_FAILURE:
      return {...state, error: action.payload.data};
    case types.ADD_LINKED_DOCUMENT_REQUEST:
      return {...state};
    case types.ADD_LINKED_DOCUMENT_SUCCESS:
      return {...state};
    case types.ADD_LINKED_DOCUMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.REMOVE_LINKED_DOCUMENT_REQUEST:
      return {...state};
    case types.REMOVE_LINKED_DOCUMENT_SUCCESS:
      return {...state};
    case types.REMOVE_LINKED_DOCUMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.ADD_COMMENT_REQUEST:
      return {...state};
    case types.ADD_COMMENT_SUCCESS:
      return {...state};
    case types.ADD_COMMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.UPLOAD_DOCUMENT_REQUEST:
      return {...state};
    case types.UPLOAD_DOCUMENT_SUCCESS:
      return {...state};
    case types.UPLOAD_DOCUMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.ARCHIVE_DOCUMENT_REQUEST:
      return {...state};
    case types.ARCHIVE_DOCUMENT_SUCCESS:
      return {...state, selectedDocument: action.payload.data};
    case types.ARCHIVE_DOCUMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.VIEW_DOCUMENT_REQUEST:
      return {...state};
    case types.VIEW_DOCUMENT_SUCCESS:
      return {...state, pdfData: action.payload.data};
    case types.VIEW_DOCUMENT_FAILURE:
      return {...state, error: action.payload.data};
    case types.SELECT_DOCUMENT:
      return {...state, selectedDocument: action.payload.document};
    default:
      return state;
  }
};

export const getDocuments = {
  request: (searchCriteria: ISearchCriteria) => action(types.GET_DOCUMENTS_REQUEST, {searchCriteria}),
  success: (data: IDocument[]) => action(types.GET_DOCUMENTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_DOCUMENTS_FAILURE, error)
};

export const getAllDocuments = {
  request: (searchCriteria: ISearchCriteria) => action(types.GET_ALL_DOCUMENTS_REQUEST, {searchCriteria}),
  success: (data: IDocument[]) => action(types.GET_ALL_DOCUMENTS_SUCCESS, data),
  failure: (error: string) => action(types.GET_ALL_DOCUMENTS_FAILURE, error)
};

export const getDocumentById = {
  request: (documentId: number) => action(types.GET_DOCUMENT_BY_ID_REQUEST, {documentId}),
  success: (data: IDocument) => action(types.GET_DOCUMENT_BY_ID_SUCCESS, data),
  failure: (error: string) => action(types.GET_DOCUMENT_BY_ID_FAILURE, error)
};

export const getDocumentPostSave = {
  request: (documentId: number) => action(types.GET_DOCUMENT_POST_SAVE_REQUEST, {documentId}),
  success: (data: IDocument) => action(types.GET_DOCUMENT_POST_SAVE_SUCCESS, data),
  failure: (error: string) => action(types.GET_DOCUMENT_POST_SAVE_FAILURE, error)
};

export const saveDocument = {
  request: (document: IDocument, file: File, lastSearch: ISearchCriteria) => action(types.SAVE_DOCUMENT_REQUEST, {document, file, lastSearch}),
  success: (document: IDocument, fileUpload: {file: File, documentVersionId: number}, lastSearch: ISearchCriteria) => action(types.SAVE_DOCUMENT_SUCCESS, {document, fileUpload, lastSearch}),
  failure: (error: string) => action(types.SAVE_DOCUMENT_FAILURE, error)
};

export const updateDocumentCategories = {
  request: (documentCategories: {documentId: number, categories: string[]}) => action(types.SAVE_DOCUMENT_CATEGORIES_REQUEST, {documentCategories}),
  success: () => action(types.SAVE_DOCUMENT_CATEGORIES_SUCCESS),
  failure: (error: string) => action(types.SAVE_DOCUMENT_CATEGORIES_FAILURE, error)
};

export const updateDocumentDepartment = {
  request: (documentDepartment: {documentId: number, department: string}) => action(types.SAVE_DOCUMENT_DEPARTMENT_REQUEST, {documentDepartment}),
  success: () => action(types.SAVE_DOCUMENT_DEPARTMENT_SUCCESS),
  failure: (error: string) => action(types.SAVE_DOCUMENT_DEPARTMENT_FAILURE, error)
};

export const reviseDocument = {
  request: (document: IDocumentVersion, file: File, lastSearch: ISearchCriteria) => action(types.REVISE_DOCUMENT_REQUEST, {document, file, lastSearch}),
  success: (data: IDocument) => action(types.REVISE_DOCUMENT_SUCCESS, data),
  failure: (error: string) => action(types.REVISE_DOCUMENT_FAILURE, error)
};

export const approveDocument = {
  request: (documentApproval: { documentId: number, documentVersionId: number, approvalDate: Date, approvalUserId: number, effectiveDate: Date }, lastSearch: ISearchCriteria) => action(types.APPROVE_DOCUMENT_REQUEST, {documentApproval, lastSearch}),
  success: (documentId: number, lastSearch: ISearchCriteria) => action(types.APPROVE_DOCUMENT_SUCCESS, {documentId, lastSearch}),
  failure: (error: string) => action(types.APPROVE_DOCUMENT_FAILURE, error)
};

export const getDocumentPostApprove = {
  success: () => action(types.GET_DOCUMENT_POST_APPROVE_SUCCESS),
  failure: (error: string) => action(types.GET_DOCUMENT_POST_APPROVE_FAILURE, error)
};

export const updateDocumentAsReviewed = {
  request: (documentReview: {documentVersionId: number, reviewDate: Date, reviewUserId: number}) => action(types.UPDATE_DOCUMENT_REVIEWED_REQUEST, {documentReview}),
  success: () => action(types.UPDATE_DOCUMENT_REVIEWED_SUCCESS),
  failure: (error: string) => action(types.UPDATE_DOCUMENT_REVIEWED_FAILURE, error)
};

export const updateTermDate = {
  request: (documentTerm: {documentId: number, documentVersionId: number, terminationDate: Date | null}) => action(types.UPDATE_TERM_DATE_REQUEST, {documentTerm}),
  success: () => action(types.UPDATE_TERM_DATE_SUCCESS),
  failure: (error: string) => action(types.UPDATE_TERM_DATE_FAILURE, error)
};

export const updateAutoRenew = {
  request: (documentAutoRenew: {documentId: number, autoRenew: boolean | null}) => action(types.UPDATE_AUTO_RENEW_REQUEST, {documentAutoRenew}),
  success: () => action(types.UPDATE_AUTO_RENEW_SUCCESS),
  failure: (error: string) => action(types.UPDATE_AUTO_RENEW_FAILURE, error)
};

export const addLinkedDocument = {
  request: (documentId: number, linkedDocumentId: number) => action(types.ADD_LINKED_DOCUMENT_REQUEST, {documentId, linkedDocumentId}),
  success: () => action(types.ADD_LINKED_DOCUMENT_SUCCESS),
  failure: (error: string) => action(types.ADD_LINKED_DOCUMENT_FAILURE, error)
};

export const removeLinkedDocument = {
  request: (documentId: number, linkedDocumentId: number) => action(types.REMOVE_LINKED_DOCUMENT_REQUEST, {documentId, linkedDocumentId}),
  success: () => action(types.REMOVE_LINKED_DOCUMENT_SUCCESS),
  failure: (error: string) => action(types.REMOVE_LINKED_DOCUMENT_FAILURE, error)
};

export const addComment = {
  request: (documentComment: IDocumentComment) => action(types.ADD_COMMENT_REQUEST, {documentComment}),
  success: () => action(types.ADD_COMMENT_SUCCESS),
  failure: (error: string) => action(types.ADD_COMMENT_FAILURE, error)
};

export const uploadDocument = {
  request: (fileUpload: {file: File, documentVersionId: number}, lastSearch?: ISearchCriteria) => action(types.UPLOAD_DOCUMENT_REQUEST, {file: fileUpload.file, documentVersionId: fileUpload.documentVersionId, lastSearch}),
  success: () => action(types.UPLOAD_DOCUMENT_SUCCESS),
  failure: (error: string) => action(types.UPLOAD_DOCUMENT_FAILURE, error)
};

export const archiveDocument = {
  request: (documentArchival: { documentId: number, archivalDate: Date, archivalUserId: number }, lastSearch: ISearchCriteria) => action(types.ARCHIVE_DOCUMENT_REQUEST, {documentArchival, lastSearch}),
  success: (documentId: number, lastSearch: ISearchCriteria) => action(types.ARCHIVE_DOCUMENT_SUCCESS, {documentId, lastSearch}),
  failure: (error: string) => action(types.ARCHIVE_DOCUMENT_FAILURE, error)
};

export const viewDocument = {
  request: (filename: string, documentVersionId: number) => action(types.VIEW_DOCUMENT_REQUEST, {filename,  documentVersionId}),
  success: (data: Uint8Array) => action(types.VIEW_DOCUMENT_SUCCESS, data),
  failure: (error: string) => action(types.VIEW_DOCUMENT_FAILURE, error)
};

export const selectDocument = {
  select: (document: IDocument) => action(types.SELECT_DOCUMENT, {document}),
};

export const actions = {
  getDocuments,
  getAllDocuments,
  getDocumentById,
  getDocumentPostSave,
  getDocumentPostApprove,
  saveDocument,
  uploadDocument,
  updateDocumentCategories,
  updateDocumentDepartment,
  reviseDocument,
  approveDocument,
  updateDocumentAsReviewed,
  updateTermDate,
  updateAutoRenew,
  addLinkedDocument,
  removeLinkedDocument,
  addComment,
  archiveDocument,
  selectDocument,
  viewDocument
};

export default reducer;
export {reducer as documentReducer};
