import {Button, Icon, Table} from "antd";
import {ColumnProps} from "antd/lib/table";
import _ from "lodash";
import {Component} from "react";
import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as userActions} from "../redux/user";
import {IUser} from "../types/server";
import AddUserModal from "./AddUserModal";

interface UserTableProps {
  fetching: boolean;
  users: any[];
  userCanEdit: boolean;
  userCanApprove: boolean;
  error?: string;
}

interface DispatchProps {
  selectUser: (user: IUser) => any;
}

interface Props extends UserTableProps, DispatchProps {}

interface UserTableLocalState {
  addUserModalVisible: boolean;
  selectedUser?: IUser;
}

class UsersTable extends Component<Props, UserTableLocalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      addUserModalVisible: false
    };
    this.setAddUserModalVisible = this.setAddUserModalVisible.bind(this);
  }

  public setAddUserModalVisible(addUserModalVisible: boolean) {
    this.setState({addUserModalVisible});
  }

  public handleEditClick(user: IUser) {
    this.props.selectUser(user);
    this.setState({addUserModalVisible: true, selectedUser: user});
  }

  public render() {
    const {fetching, users, userCanEdit, userCanApprove} = this.props;

    if (fetching) {
      const divStyle = {width: "100%", height: "75%", display: "flex", alignItems: "center", justifyContent: "center"};
      return <div style={divStyle}><Button size="large" shape="circle" loading/></div>;
    }

    const usersList = users.map((user) => Object.assign(user, {key: user.id}, {name: user.firstName + " " + user.lastName}));

    const editRenderer = (text: any, record: any, index: any) => {
      const user: IUser = record;
      const style = {width: "100px", fontSize: "20px"};

      if ((userCanEdit || userCanApprove)) {
        return <Icon type="edit" style={style} onClick={() => this.handleEditClick(user)}>Edit</Icon>;
      }
    };

    const columns: Array<ColumnProps<IUser>> = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a: IUser, b: IUser) => a.firstName.localeCompare(b.firstName)
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        defaultSortOrder: "ascend",
        sorter: (a: IUser, b: IUser) => {
          if (a.email && b.email) {
            return a.email.localeCompare(b.email);
          } else {
            return 0;
          }
        }
      },
      {
        title: "Departments",
        render: (text: any, record: any) => (
          <span>
              {record.departments.map((d: any) => d.description).join(", ")}
          </span>
        )
      },
      {
        title: "Permission",
        dataIndex: "permission",
        key: "permission",
        defaultSortOrder: "ascend",
        sorter: (a: any, b: any) => a.permission.localeCompare(b.permission),
        render: (text: any, record: any) => (
          <span>
            {_.capitalize(record.permission)}
          </span>
        )
      },
      {
        title: "",
        render: editRenderer,
        width: userCanEdit ? 180 : 0
      }
    ];
    return (
      <div>
        <Table<IUser> dataSource={usersList} columns={columns}/>
        <AddUserModal addUserModalVisible={this.state.addUserModalVisible}
                      setVisible={this.setAddUserModalVisible}/>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    selectUser: (user: IUser) => dispatch(userActions.selectUser.select(user))
  };
};

export default connect(null, mapDispatchToProps)(UsersTable);
