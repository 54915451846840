import {Badge, Button, Col, Collapse, Form, Row} from "antd";
import Search from "antd/es/input/Search";
import _ from "lodash";
import React, {FormEvent} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as appActions} from "../redux/app";
import {actions as userActions} from "../redux/user";
import {ApplicationState, AppUtilityState, UserSearchCriteriaState} from "../types";
import {IUserSearchCriteria} from "../types/server";

interface Props extends AppUtilityState, DispatchProps {}

class UsersSearchCriteria extends React.Component<Props, UserSearchCriteriaState> {

    constructor(props: any) {
      super(props);
      this.state = {
        searchCriteria: {},
        isDirty: false
      };
    }

  public componentDidMount(): void {
    this.props.fetchUserDepartmentCounts();
    this.props.fetchUserPermissionLevelCounts();
  }

  public searchUsers = () => {
    console.log("searchCriteria ", this.state.searchCriteria);
    this.props.fetchUsers(this.state.searchCriteria);
    this.setState({isDirty: false});
  }

  public handleSearch = () => {
    this.searchUsers();
  }

  public handleSearchChange = (e: FormEvent<HTMLInputElement>) => {
    this.setState({searchCriteria: {...this.state.searchCriteria, searchString: e.currentTarget.value}, isDirty: true});
  }

  public handleClearAll =  (e: FormEvent) => {
    e.stopPropagation();
    this.setState({searchCriteria: {}, isDirty: false}, () => {
      this.props.fetchUsers(this.state.searchCriteria);
    });
  }

  public handleSearchSubmit = () => {
    if (this.state.searchCriteria.searchString) {
      this.searchUsers();
    }
  }

  public handleDepartmentClick = (department: string) => () => {
    this.setState({searchCriteria: {...this.state.searchCriteria, department}, isDirty: false}, () => {
      this.props.fetchUsers(this.state.searchCriteria);
    });
  }

  public handlePermissionLevelClick = (permissionLevel: string) => () => {
    this.setState({searchCriteria: {...this.state.searchCriteria, permissionLevel}, isDirty: false}, () => {
      this.props.fetchUsers(this.state.searchCriteria);
    });
  }

  public handleDepartmentReset = (e: FormEvent) => {
    e.stopPropagation();
    this.setState({searchCriteria: {...this.state.searchCriteria, department: null}, isDirty: false}, () => {
      this.props.fetchUsers(this.state.searchCriteria);
    });
  }

  public departmentResetElement = () => {
    if (this.state.searchCriteria.department && this.state.searchCriteria.department.length > 0) {
      return <Button size="small" onClick={this.handleDepartmentReset}>Reset</Button>;
    } else {
      return null;
    }
  }

  public handlePermissionLevelReset = (e: FormEvent) => {
    e.stopPropagation();
    this.setState({searchCriteria: {...this.state.searchCriteria, permissionLevel: null}, isDirty: false}, () => {
      this.props.fetchUsers(this.state.searchCriteria);
    });
  }

  public permissionLevelResetElement = () => {
    if (this.state.searchCriteria.permissionLevel && this.state.searchCriteria.permissionLevel.length > 0) {
      return <Button size="small" onClick={this.handlePermissionLevelReset}>Reset</Button>;
    } else {
      return null;
    }
  }

  public render() {

    const {userDepartmentCounts, userPermissionLevelCounts} = this.props;

    // TODO: handle null department? now it will return all users. Do we need to? Can/should we make the column not null?
    const userDepartmentList = userDepartmentCounts.map((item) => (
      <div key={item.department}>
        <Row className={this.state.searchCriteria.department === item.department ? "row-selected" : ""}>
          <Col span={18}>
            <Button type="link" onClick={this.handleDepartmentClick(item.department)}>{item.department} </Button>
          </Col>
          <Col span={6} >
            <div style={{position: "absolute", right: "0px"}}>
              <Badge style={{ backgroundColor: "#fff", color: "#7e7e7e", boxShadow: "0 0 0 1px #d9d9d9 inset" }} count={item.count} overflowCount={1000} />
            </div>
          </Col>
        </Row>
      </div>
    ));

    // TODO: handle null permission level? now it will return all users. Do we need to? Can/should we make the column not null?
    const userPermissionLevelList = userPermissionLevelCounts.map((item) => (
      <div key={item.permissionLevel}>
        <Row type="flex" className={this.state.searchCriteria.permissionLevel === item.permissionLevel ? "row-selected" : ""}>
          <Col span={18} >
            <Button type="link" onClick={this.handlePermissionLevelClick(item.permissionLevel)}>{(item.permissionLevel == null ? "No Permission Level" :  _.capitalize(item.permissionLevel))} </Button>
          </Col>
          <Col span={6}>
            <div style={{position: "absolute", right: "0px"}}>
              <Badge style={{ backgroundColor: "#fff", color: "#999", boxShadow: "0 0 0 1px #d9d9d9 inset" }} count={item.count} overflowCount={1000}/>
            </div>
          </Col>
        </Row>
      </div>
    ));

    return (
      <Form id="SearchCriteria" layout="vertical">
        <div style={{margin: "10px"}}>
          <p style={{fontSize: "16px"}}>SEARCH</p>
          <Row type="flex" style={{marginTop: "5px", borderBottom: 0}}>
            <Col span={12}>
              <Button htmlType="button" onClick={this.handleClearAll} >Clear All</Button>
            </Col>
            <Col span={12} push={5}>
              <Button htmlType="button" onClick={this.handleSearch} disabled={!this.state.isDirty}>Apply</Button>
            </Col>
          </Row>
          <p style={{height: "10px", margin: "0px"}}/>
          <Search
            placeholder="input search text"
            style={{ marginTop: "15px", width: 227 }}
            value={this.state.searchCriteria.searchString}
            onChange={this.handleSearchChange}
            onSearch={this.handleSearchSubmit}
            enterButton
          />

        </div>
        <Collapse defaultActiveKey={["1", "2"]}>
          <Collapse.Panel header="Department" key="1" extra={this.departmentResetElement()}>
            {userDepartmentList}
          </Collapse.Panel>
          <Collapse.Panel header="Permission Level" key="2" extra={this.permissionLevelResetElement()}>
            {userPermissionLevelList}
          </Collapse.Panel>
        </Collapse>
      </Form>
    );
  }
}

const mapStateToProps = (state: ApplicationState): AppUtilityState => {

  const {app: appUtilityState} = state;

  return {
    fetchingDepartments: appUtilityState.fetchingDepartments,
    departments: appUtilityState.departments,
    errorDepartments: appUtilityState.errorDepartments,
    fetchingCategories: appUtilityState.fetchingCategories,
    categories: appUtilityState.categories,
    errorCategories: appUtilityState.errorCategories,
    documentTypeCounts: appUtilityState.documentTypeCounts,
    documentStatusCounts: appUtilityState.documentStatusCounts,
    userDepartmentCounts: appUtilityState.userDepartmentCounts,
    userPermissionLevelCounts: appUtilityState.userPermissionLevelCounts
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchUsers: (searchCriteria: IUserSearchCriteria) => dispatch(userActions.getUsers.request(searchCriteria)),
    fetchUserDepartmentCounts: () => dispatch(appActions.getUserDepartmentCounts.request()),
    fetchUserPermissionLevelCounts: () => dispatch(appActions.getUserPermissionLevelCounts.request())
  };
};

interface ConnectedSearchCriteriaDispatchProps {}

interface DispatchProps extends ConnectedSearchCriteriaDispatchProps {
    fetchUsers: (searchCriteria: IUserSearchCriteria) => any;
    fetchUserDepartmentCounts: () => any;
    fetchUserPermissionLevelCounts: () => any;
}

const ConnectedSearchCriteria: React.ComponentClass<ConnectedSearchCriteriaDispatchProps>  =
    connect<AppUtilityState, DispatchProps, ConnectedSearchCriteriaDispatchProps, ApplicationState>(mapStateToProps, mapDispatchToProps)(UsersSearchCriteria);

export default ConnectedSearchCriteria;
