import {Button, Col, Collapse, DatePicker, Icon, Layout, List, Row, Select, Switch, Tag} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, {Component, FormEvent} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {actions as appActions} from "../redux/app";
import {actions as documentActions} from "../redux/documents";
import {ApplicationState, DocumentDetailLocalState, DocumentDetailState} from "../types";
import {IBaseDocument, ICategory, IDocument, IDocumentComment} from "../types/server";
import ArchiveDocumentModal from "./ArchiveDocumentModal";
import HistoryModal from "./HistoryModal";
import ReviewDocumentModal from "./ReviewDocumentModal";
import ReviseDocumentModal from "./ReviseDocumentModal";
import SelectWithList from "./SelectWithList";

const {Sider} = Layout;

interface DispatchProps {
  fetchCategories: () => any;
  updateDocumentCategories: (documentCategories: {documentId: number, categories: string[]}) => any;
  updateDocumentDepartment: (documentDepartment: {documentId: number, department: string}) => any;
  updateDocumentAsReviewed: (documentReview: {documentVersionId: number, reviewDate: Date, reviewUserId: number}) => any;
  updateTermDate: (documentTerm: {documentId: number, documentVersionId: number, terminationDate: Date | null}) => any;
  updateAutoRenew: (documentAutoRenew: {documentId: number, autoRenew: boolean | null}) => any;
  addLinkedDocument: (documentId: number, linkedDocumentId: number) => any;
  removeLinkedDocument: (documentId: number, linkedDocumentId: number) => any;
  addComment: (documentComment: IDocumentComment) => any;
  selectDocument: (document: IDocument) => any;
}

interface Props extends DispatchProps, DocumentDetailState {}

class DocumentDetailSideBar extends Component<Props, DocumentDetailLocalState> {

  public filteredCategories: ICategory[] = this.props.categories;
  public filteredDocumentsToLink: IBaseDocument[] = this.props.allDocuments;

  constructor(props: any) {
    super(props);
    this.state = {
      editingDepartment: false,
      editingTermDate: false,
      categoryToAdd: null,
      linkedDocumentToAdd: null,
      commentToAdd: "",
      confirmReviewModalVisible: false,
      confirmArchiveModalVisible: false,
      reviseDocumentVisible: false,
      historyModalVisible: false
    };
  }

  public componentDidMount(): void {
    if (this.props.categories === null) {
      this.props.fetchCategories();
    }
  }

  public onEditTermDateClick = () => {
    this.setState({editingTermDate: true});
  }

  public onTermDateChange = (date: moment.Moment, dateString: string) => {
    this.props.updateTermDate({documentId: this.props.document.id, documentVersionId: this.props.document.documentVersionId, terminationDate: (date ? date.toDate() : null)});
    this.setState({editingTermDate: false});
  }

  public onAutoRenewChange = (checked: boolean) => {
    this.props.updateAutoRenew({documentId: this.props.document.id, autoRenew: checked});
  }

  public onMarkReviewedClick = () => {
    this.setState({confirmReviewModalVisible: true});
  }

  public onArchiveClick = () => {
    this.setState({confirmArchiveModalVisible: true});
  }

  public onEditDepartmentClick = () => {
    this.setState({editingDepartment: true});
  }

  public onDepartmentChange = (newValue: string) => {
    if (!newValue) {
      this.setState({editingDepartment: false});
    } else {
      this.setState({updatedDepartment: newValue});
    }
  }

  public onDepartmentSave = () => {
    if (this.state.updatedDepartment != null) {
      this.props.updateDocumentDepartment({documentId: this.props.document.id, department: this.state.updatedDepartment});
      this.setState({editingDepartment: false, updatedDepartment: ""});
    }
  }

  public onCategoryChange = (selectedCategoryId: number) => {
    const categoryToAdd: ICategory = this.props.categories.find((category: ICategory) => category.id === selectedCategoryId);
    this.setState({categoryToAdd});
  }

  public handleRemoveCategory = (categoryToRemove: any) => {
    const updatedCategories: string[] = this.props.document.categories.filter(
      (category: string) => category !== categoryToRemove.category);
    this.props.updateDocumentCategories({documentId: this.props.document.id, categories: updatedCategories});
  }

  public handleAddCategory = () => {
    const updatedCategories: string[] = this.props.document.categories;
    updatedCategories.push(this.state.categoryToAdd.name);
    this.props.updateDocumentCategories({documentId: this.props.document.id, categories: updatedCategories});
    this.setState({categoryToAdd: null});
  }

  public onLinkedDocumentChange = (selectedLinkedDocumentId: number) => {
    const linkedDocumentToAdd: IBaseDocument = this.props.allDocuments.find((document) => document.id === selectedLinkedDocumentId);
    this.setState({linkedDocumentToAdd});
  }

  public handleRemoveLinkedDocument = (selectedLinkedDocumentId: number | undefined) => {
    if (selectedLinkedDocumentId) {
      const linkedDocumentToRemove: IBaseDocument = this.props.document.linkedDocuments.find((document: IBaseDocument) => document.id === selectedLinkedDocumentId);
      if (linkedDocumentToRemove.id) {
        this.props.removeLinkedDocument(this.props.document.id, linkedDocumentToRemove.id);
      }
    }
  }

  public handleAddLinkedDocument = () => {
    if (this.state.linkedDocumentToAdd) {
      this.props.addLinkedDocument(this.props.document.id, this.state.linkedDocumentToAdd.id);
      this.setState({linkedDocumentToAdd: null});
    }
  }

  public handleCommentChange = (e: FormEvent<HTMLTextAreaElement>) => {
    this.setState({commentToAdd: e.currentTarget.value});
  }

  public handleAddComment = () => {
    if (this.state.commentToAdd) {
      const document = this.props.document;
      const newComment: IDocumentComment = {
        documentId: document.id,
        userId : this.props.authorizedUser.id,
        userName : this.props.authorizedUser.firstName + " " + this.props.authorizedUser.lastName,
        commentDate : new Date(),
        comment : this.state.commentToAdd
      };
      this.props.addComment(newComment);
      this.setState({commentToAdd: ""});
    }
  }

  public setReviseDocumentModalVisible = (reviseDocumentVisible: boolean) => {
    this.setState({reviseDocumentVisible});
  }

  public setConfirmReviewModalVisible = (confirmReviewModalVisible: boolean) => {
    this.setState({confirmReviewModalVisible});
  }

  public setHistoryModalVisible = (historyModalVisible: boolean) => {
    this.setState({historyModalVisible});
  }

  public setConfirmArchiveModalVisible = (confirmArchiveModalVisible: boolean) => {
    this.setState({confirmArchiveModalVisible});
  }

  public viewDocument = (item: any) => {

    const versionString: string = (item.documentVersionId) ?
      "_v" + item.version.toString() + "_" + item.id.toString() + "_" + item.documentVersionId.toString()
    : "_v" + item.version.toString() + "_" + item.documentId.toString() + "_" + item.id.toString();  // properties different for document vs revisions

    const baseFilename = item.pdfFilename.substring(0, item.pdfFilename.indexOf(versionString)) + ".pdf";

    let url = window.location.origin + "/api/documents/viewDocumentPdf/";
    url += baseFilename;
    url += "?versionString=" + versionString;
    url += (item.documentVersionId) ? "&documentId=" + item.id : "&documentId=" + item.documentId; // properties different for document vs revisions
    url += "&accessToken=" + localStorage.getItem("accessToken");
    window.open(url);
  }

  public downloadDocument = (item: any) => {
    const dotLoc = item.filename.lastIndexOf(".");
    const extension = item.filename.substring(dotLoc, item.filename.length);
    const versionString: string = (item.documentVersionId) ?
      "_v" + item.version.toString() + "_" + item.id.toString() + "_" + item.documentVersionId.toString()
    : "_v" + item.version.toString() + "_" + item.documentId.toString() + "_" + item.id.toString(); // properties different for document vs revisions
    const baseFilename = item.filename.substring(0, item.filename.indexOf(versionString)) + extension;

    let url = window.location.origin + "/api/documents/downloadDocument/";
    url += baseFilename;
    url += "?versionString=" + versionString;
    url += (item.documentVersionId) ? "&documentId=" + item.id : "&documentId=" + item.documentId; // properties different for document vs revisions
    url += "&accessToken=" + localStorage.getItem("accessToken");
    window.open(url);
  }

  public render() {

    const {document, authorizedUser} = this.props;

    if (document && document.categories) {
      this.filteredCategories = this.props.categories.filter((category: ICategory) => !document.categories.includes(category.name));
      this.filteredDocumentsToLink = this.props.allDocuments.filter(
        (doc: IBaseDocument) => !document.linkedDocuments.includes(doc));
    } else {
      this.filteredCategories = this.props.categories;
      this.filteredDocumentsToLink = this.props.allDocuments;
    }

    const categoryList = (document && document.categories ?
      document.categories.map((category: string) => (<Tag closable key={category} onClose={() => this.handleRemoveCategory({category})}>{category}</Tag>)) : null);

    const readOnlyCategoryList = (document && document.categories ?
      document.categories.map((category: string) => (<Tag key={category}>{category}</Tag>)) : null);

    const downloadButtonHidden: boolean = (document && document.filename !== null && authorizedUser.canEdit) ? false : true;
    const viewButtonHidden: boolean = (document && document.pdfFilename !== null) ? false : true;

    const linkedDocumentsList = (document: IDocument) => {
      if (!document || !document.linkedDocuments) { return null; }

      if (authorizedUser.canEdit) {
        return (
          <List style={{marginTop: 0}} dataSource={document.linkedDocuments}
                renderItem={(item: IBaseDocument) =>
                  (<List.Item actions={[<Button icon={"close-circle"} size="small"
                                                style={{position: "absolute", top: 0, right: 0}}
                                                onClick={() => this.handleRemoveLinkedDocument(item.id)}/>]}>
                    {item.title}
                  </List.Item>)
                }>
          </List>
        );
      } else {
        return (
          <List style={{marginTop: 0}} dataSource={document.linkedDocuments}
                renderItem={(item: IBaseDocument) => (<List.Item>{item.title}</List.Item>)}>
          </List>);
      }
    };

    const commentList = (document: IDocument) => {
      if (!document || !document.documentComments) { return null; }

      return (
        <List style={{height: "100%"}} dataSource={document.documentComments}
              renderItem={(item: IDocumentComment) =>
                (
                <div style={{marginBottom: 10, height: "100%"}}>
                  <span style={{fontSize: "10"}}> {item.userName} </span> - <span style={{fontSize: "10", height: 20, fontWeight: "lighter"}}>{moment(item.commentDate).fromNow()} </span> <br/>
                  <span style={{fontSize: "12", fontWeight: "bold"}}> {item.comment} </span>
                </div>
                )}>
        </List>
      );
    };

    const displayDepartmentSelect: string = this.state.editingDepartment ? "inline" : "none";

    const applyPermissionToDisplay = (permitted: boolean, component: any, alternateComponent: any) => {
      return (permitted ? component : alternateComponent);
    };

    if (document) {
      return (
        <Sider theme="light" width={350} style={{paddingLeft: "25px", paddingRight: "25px", paddingTop: "10px"}}>
          <Row type="flex" justify="space-around" style={{width: "100%", paddingBottom: "20px"}}>
            <Col style={{paddingLeft: viewButtonHidden ? "70px" : "0px"}}>
              <Button hidden={viewButtonHidden} type="primary" size="small" onClick={(event: React.MouseEvent<any, MouseEvent>) => this.viewDocument(this.props.document)}>View <Icon type="file-pdf"/></Button>
            </Col>
            <Col>
              <Button hidden={downloadButtonHidden} type="primary" size="small" onClick={() => this.downloadDocument(this.props.document)}>Download <Icon type="download"/></Button>
            </Col>
            <Col>
              {applyPermissionToDisplay(authorizedUser.canEdit,
                (<Button type="primary" size="small" onClick={() => this.setReviseDocumentModalVisible(true)}>Revise <Icon type="upload"/></Button>),
                (<div/>))}
            </Col>
          </Row>
          <Row style={{paddingBottom: "10px"}}>
            <span style={{fontSize: "16px", fontWeight: "bold", color: "lightgrey"}}>DOCUMENT DETAILS</span>
          </Row>
          <Row>
            <span style={{fontSize: "16px", fontWeight: "lighter"}}>Title</span>
          </Row>
          <Row style={{paddingBottom: "10px"}}>
            <span style={{fontSize: "18px"}}>{document.title}</span>
          </Row>
          <Row>
            <Col span={9}>
              <div style={{paddingTop: "3px", fontSize: "16px", fontWeight: "lighter"}}>Effective Date</div>
            </Col>
            <Col span={9}>
              <div style={{fontSize: "16px", fontWeight: "lighter"}}>Term Date
                {applyPermissionToDisplay(authorizedUser.canEdit,
                              <Button type="link" icon={"edit"} onClick={this.onEditTermDateClick} />,
                              <div/>)}
              </div>
            </Col>
            <Col span={6}>
              <div style={{paddingLeft: "10px", fontSize: "12px"}}>Auto Renew</div>
            </Col>
          </Row>
          <Row style={{paddingBottom: "10px"}}>
            <Col span={9}>
              <div style={{fontSize: "18px"}}>{document.effectiveDate ? moment(document.effectiveDate).format("YYYY-MM-DD") : "n/a"}</div>
            </Col>
            <Col span={9}>
              <div style={{fontSize: "18px"}}>
                {document.terminationDate ? moment(document.terminationDate).format("YYYY-MM-DD") : "n/a"}
                <DatePicker style={{display: this.state.editingTermDate ? "inline" : "none"}} value={document.terminationDate ? moment(document.terminationDate) : undefined} allowClear={true} onChange={this.onTermDateChange} />
              </div>
            </Col>
            <Col span={6}>
              <div style={{paddingLeft: "10px"}}><Switch size={"small"} checked={document.autoRenew === 0 ? false : true} onClick={this.onAutoRenewChange} /></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <span style={{fontSize: "16px", fontWeight: "lighter"}}>Revised Date</span>
            </Col>
          </Row>
          <Row type="flex" style={{paddingBottom: "10px"}}>
            <Col>
              <span style={{fontSize: "18px"}}>{document.revisedDate ? moment(document.revisedDate).format("YYYY-MM-DD") : "n/a"}</span>
            </Col>
          </Row>
          <Row>
            <span style={{fontSize: "16px", fontWeight: "lighter"}}>Reviewed Date</span>
          </Row>
          <Row type="flex" style={{paddingBottom: "10px"}}>
            <Col>
              <span style={{fontSize: "18px"}}>{document.reviewedDate ? moment(document.reviewedDate).format("YYYY-MM-DD") : "n/a"}</span>
            </Col>
            <Col style={{position: "absolute", right: 20}}>
              {applyPermissionToDisplay(authorizedUser.canEdit,
                (<Button size="small" type="primary" onClick={this.onMarkReviewedClick}>Mark Reviewed</Button>),
                (<div/>))}
            </Col>
          </Row>
          <Row>
            <span style={{fontSize: "16px", fontWeight: "lighter"}}>Responsible Party</span>
          </Row>
          <Row style={{paddingBottom: "10px"}}>
            <p><span style={{fontSize: "18px"}}>{document.responsibleDepartment}</span>
              {applyPermissionToDisplay(authorizedUser.canEdit,
                <Button type="link" icon={"edit"} onClick={this.onEditDepartmentClick} />,
                <div/>)}</p>
            <div style={{display: displayDepartmentSelect, width: "90%"}}>
              <SelectWithList items={this.props.departments} placeholder="Responsible Department"
                              onChange={this.onDepartmentChange}
                              style={{width: "60%"}} />
              <Button size="small" disabled={!this.state.updatedDepartment} onClick={this.onDepartmentSave} style={{ width: "30%" }}>
                <Icon type="save" /> Update
              </Button>
            </div>
          </Row>
          <Row>
            <span style={{fontSize: "16px", fontWeight: "lighter"}}>Categories</span>
          </Row>
          <Row style={{paddingBottom: "10px"}}>
            {applyPermissionToDisplay(authorizedUser.canEdit, categoryList, readOnlyCategoryList)}
            {applyPermissionToDisplay(authorizedUser.canEdit,
              <Select value={(this.state.categoryToAdd ? this.state.categoryToAdd.id : null)}
                    showSearch
                    placeholder="Category"
                    onChange={this.onCategoryChange}
                    optionFilterProp="name"
                    filterOption={(input, option) => {
                      if (option && option.props && option.props.children) {
                        return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      } else {
                        return false;
                      }
                    }}
                    allowClear={true}
                    style={{ width: "60%" }}
            >
              {this.filteredCategories.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>, <div/>)}
            {applyPermissionToDisplay(authorizedUser.canEdit,
              <Button size="small" disabled={!this.state.categoryToAdd} onClick={this.handleAddCategory} style={{ width: "60%" }}>
              <Icon type="plus" /> Add Category
            </Button>, <div/>)}
          </Row>
          {applyPermissionToDisplay(authorizedUser.canEdit,
            <Row style={{paddingBottom: "10px"}}>
              <Button onClick={() => this.setHistoryModalVisible(true)}>Display History</Button>
            </Row>, <div/>)}
          <Collapse defaultActiveKey={["2"]}>
            <Collapse.Panel header="Linked Documents" key="1">
              {linkedDocumentsList(document)}
              {applyPermissionToDisplay(authorizedUser.canEdit,
              <Select value={(this.state.linkedDocumentToAdd ? this.state.linkedDocumentToAdd.id : null)}
                      showSearch
                      placeholder="Linked Document"
                      onChange={this.onLinkedDocumentChange}
                      optionFilterProp="title"
                      filterOption={(input, option) => {
                        if (option && option.props && option.props.children) {
                          return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        } else {
                          return false;
                        }
                      }
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: 10 }}
              >
                {this.filteredDocumentsToLink.map((linkedDocument) => (
                  <Select.Option key={linkedDocument.id} value={linkedDocument.id}>
                    {linkedDocument.title}
                  </Select.Option>
                ))}
              </Select>, <div/>)}
              {applyPermissionToDisplay(authorizedUser.canEdit,
              <Row type="flex" justify="center" style={{width: "100%"}}>
                  <Button disabled={!this.state.linkedDocumentToAdd} onClick={this.handleAddLinkedDocument} >
                    <Icon type="plus" /> Add Document Link
                  </Button>
              </Row>, <div/>)}
            </Collapse.Panel>
            <Collapse.Panel header="Comments" key="2">
              {commentList(document)}
              <TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                maxLength={2000}
                value={this.state.commentToAdd}
                onChange={this.handleCommentChange}
              />
              <Row type="flex" justify="center" style={{marginTop: 15, width: "100%"}}>
                  <Button size="small" disabled={this.state.commentToAdd == null ? true : this.state.commentToAdd.length === 0}
                          onClick={this.handleAddComment}>
                    Add Comment
                  </Button>
              </Row>
            </Collapse.Panel>
          </Collapse>
          <Row type="flex" style={{paddingTop: "10px"}}>
              {applyPermissionToDisplay(authorizedUser.canApprove,
                (<Button size="small" type="primary" onClick={this.onArchiveClick}>Archive Document</Button>),
                (<div/>))}
          </Row>
          <ReviewDocumentModal setVisible={this.setConfirmReviewModalVisible}
                               confirmReviewModalVisible={this.state.confirmReviewModalVisible}
                               document={document} />
          <ReviseDocumentModal setVisible={this.setReviseDocumentModalVisible}
                               reviseDocumentModalVisible={this.state.reviseDocumentVisible}
          />
          {applyPermissionToDisplay(authorizedUser.canEdit,
            (<HistoryModal document={document}
                        setVisible={this.setHistoryModalVisible}
                        historyModalVisible={this.state.historyModalVisible}
                        />),
            (<div/>))}
          <ArchiveDocumentModal setVisible={this.setConfirmArchiveModalVisible}
                               confirmArchiveModalVisible={this.state.confirmArchiveModalVisible}
                               document={document} />
        </Sider>
      );
    } else {
      return <Sider theme="light"/>;
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchCategories: () => dispatch(appActions.getCategories.request()),
    updateDocumentCategories: (documentCategories: {documentId: number, categories: string[]}) => dispatch(documentActions.updateDocumentCategories.request(documentCategories)),
    updateDocumentDepartment: (documentDepartment) => dispatch(documentActions.updateDocumentDepartment.request(documentDepartment)),
    updateDocumentAsReviewed: (documentReview) => dispatch(documentActions.updateDocumentAsReviewed.request(documentReview)),
    updateTermDate: (documentTerm) => dispatch(documentActions.updateTermDate.request(documentTerm)),
    updateAutoRenew: (documentAutoRenew) => dispatch(documentActions.updateAutoRenew.request(documentAutoRenew)),
    addLinkedDocument: (documentId: number, linkedDocumentId: number) => dispatch(documentActions.addLinkedDocument.request(documentId, linkedDocumentId)),
    removeLinkedDocument: (documentId: number, linkedDocumentId: number) => dispatch(documentActions.removeLinkedDocument.request(documentId, linkedDocumentId)),
    addComment: (documentComment: IDocumentComment) => dispatch(documentActions.addComment.request(documentComment)),
    selectDocument: (document: IDocument) => dispatch(documentActions.selectDocument.select(document))
  };
};

const mapStateToProps = (state: ApplicationState): DocumentDetailState => {

  const {app: appUtilityState, documents: documentState, auth: authState} = state;

  return {
    allDocuments: documentState.allDocuments,
    document: documentState.selectedDocument,
    categories: appUtilityState.categories,
    departments: appUtilityState.departments,
    authorizedUser: authState.authorizedUser
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (DocumentDetailSideBar);
