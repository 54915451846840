import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {routerMiddleware} from "connected-react-router";
import {History} from "history";
import {applyMiddleware, createStore, Store} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import {types} from "../redux/auth";
import {initialState as initialApplicationState, rootReducer} from "../redux/reducer";
import {rootSaga} from "../sagas/saga";
import {ApplicationState} from "../types";
const jwtDecode = require("jwt-decode");

export default function configureStore(history: History, initialState: ApplicationState = initialApplicationState): Store<ApplicationState> {

  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)));

  sagaMiddleware.run(rootSaga);

  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const decodedToken = jwtDecode(authToken);
    if (decodedToken && decodedToken.user) {
      store.dispatch({type: types.SET_USER, payload: decodedToken.user});
    }
  }

  axios.interceptors.response.use((response: AxiosResponse) => {
    return response;
  }, (error: any) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      store.dispatch({type: types.UNAUTHORIZED});
    }
    return Promise.reject(error);
  });

  // create axios interceptor
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.authorization = localStorage.getItem("accessToken");
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  return store;
}

// const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();
//
// let store = createStore(
//     reducer,
//     compose(applyMiddleware(sagaMiddleware), reduxDevTools)
// );
